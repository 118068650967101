import { useQuery } from '@tanstack/react-query'
import { useCabanaApi } from '../hooks/useCabanaApi'

export const useMyGroups = () => {
  const { getMyGroups } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['My Groups'],
    queryFn: () => getMyGroups(),
  })
  return { data, isLoading }
}
