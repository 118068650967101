import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'
import BottomSheet from '../_shared/notification/BottomSheet'
import FilterPillContainer from './FilterPillContainer'
import ModeratorsFilterView from './ModeratorsFilterView'

const ModeratorsFilter = ({
  moderatorFilters,
  setModeratorFilters,
  onRemoveAllFilters,
  setIsFilterModalOpen,
}) => {
  const [moderatorsFilterModalOpen, setModeratorsFilterModalOpen] =
    useState(false)
  useEffect(() => {
    setIsFilterModalOpen(moderatorsFilterModalOpen)
  }, [moderatorsFilterModalOpen])

  return (
    <>
      <FilterPillContainer
        key="moderatorsFilter"
        isApplied={moderatorFilters.length > 0}
        onPress={() => {
          Keyboard.dismiss()
          setModeratorsFilterModalOpen(true)
        }}
        text={
          'Moderators' +
          (moderatorFilters.length > 1 ? ` (${moderatorFilters.length})` : '')
        }
      />
      <BottomSheet
        isOpen={moderatorsFilterModalOpen}
        handleClose={() => setModeratorsFilterModalOpen(false)}
        title="Moderators"
        body={
          <ModeratorsFilterView
            appliedFilters={moderatorFilters}
            setFilters={setModeratorFilters}
            onRemoveAllFilters={onRemoveAllFilters}
          />
        }
        hasCloseOnBottom={true}
        width="415px"
      />
    </>
  )
}

export default ModeratorsFilter
