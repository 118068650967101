import { HStack, Select, Spinner, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useMicrophonesContext } from '../../providers/MicrophoneProvider'
import FaIcon from '../_shared/FaIcon'
import MicrophoneLevel from './MicrophoneLevel'

const MicrophonePicker = () => {
  const {
    loading,
    error,
    microphones,
    refresh,
    selectMicrophone,
    selectedMicrophone,
  } = useMicrophonesContext()

  const [selectedValue, setSelectedValue] = useState(
    selectedMicrophone?.name || ''
  )

  useEffect(() => {
    setSelectedValue(selectedMicrophone?.name || '')
  }, [selectedMicrophone])

  useEffect(() => {
    if (microphones.length === 0) refresh()
  }, [])

  const handleSelect = (selectedName: string) => {
    const selectedMicrophone = microphones.find(
      (microphone) => microphone.name === selectedName
    )
    if (selectedMicrophone) selectMicrophone(selectedMicrophone)
  }

  const picker = (
    <VStack space={4}>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        space={4}
        height={10}>
        {error && <FaIcon name="exclamation-triangle" color="error.500" />}
        <Select
          flex={1}
          selectedValue={selectedValue}
          accessibilityLabel="Select Microphone"
          placeholder="Select Microphone"
          size="lg"
          onValueChange={handleSelect}>
          {microphones.map((mic) => (
            <Select.Item key={mic.name} label={mic.name} value={mic.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => refresh()} />
      </HStack>
      <MicrophoneLevel />
    </VStack>
  )

  const spinner = (
    <HStack
      alignItems="center"
      justifyContent="flex-start"
      space={4}
      height={16}>
      <Spinner />
      <Text>Loading microphones...</Text>
    </HStack>
  )

  return loading ? spinner : picker
}

export default MicrophonePicker
