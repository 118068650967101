import { useState } from 'react'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import delayPromise from '../../helpers/delayPromise'
import { UseMicrophones } from './useMicrophones'

const fetchMicrophones: () => Promise<Microphone[]> = async () => {
  // permission check
  await navigator.mediaDevices.getUserMedia({ audio: true })

  // map devices to microphones
  const devices = await navigator.mediaDevices.enumerateDevices()
  const audioDevices = devices.filter((device) => device.kind === 'audioinput')
  const microphones = audioDevices.map((device) => ({
    name: device.label || 'Unnamed microphone',
    object: device,
  }))
  return microphones
}

export const useMicrophones: UseMicrophones = () => {
  const [loading, setLoading] = useState(false)
  const [microphones, setMicrophones] = useState<Microphone[]>([])
  const [error, setError] = useState<string | null>(null)

  const refresh = async () => {
    setLoading(true)

    try {
      const fetchedMicrophones = await delayPromise(fetchMicrophones(), 500)
      setMicrophones([...fetchedMicrophones, NO_MICROPHONE])
      setError(null)
    } catch (error) {
      await delayPromise(Promise.resolve(), 500)
      setMicrophones([NO_MICROPHONE])
      setError(error.message)
    }
    setLoading(false)
  }

  return {
    loading,
    microphones,
    error,
    refresh,
  }
}

export default useMicrophones
