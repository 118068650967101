import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import secureScreen from '../hooks/authentication/secureScreen'
import { useCabanaApi } from '../hooks/useCabanaApi'
import LoadingScreen from './LoadingScreen'
import { NavigationParams } from './NavigationScreen'

const ReferralSignUpScreen = ({ route }) => {
  const referralCode = route.params.referralCode
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const queryClient = useQueryClient()
  const { activateMyAccount } = useCabanaApi()

  const [message, setMessage] = useState('Processing your referral...')

  useEffect(() => {
    activateMyAccount(referralCode)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Member'],
        })
        navigation.navigate('Home')
      })
      .catch(() =>
        setMessage(
          'We were unable to process your referral. Please contact support@yourcabana.com'
        )
      )
  }, [])

  return <LoadingScreen title={message} hasRandomMessage={false} />
}

export default secureScreen(ReferralSignUpScreen, { screen_hint: 'signup' })
