import { Box, HStack, Image, VStack } from 'native-base'
import { ImageBackground, useWindowDimensions } from 'react-native'
import BackButton from '../navigation/BackButton'
import { SkeletonLoader } from '../_shared/SkeletonLoader'

const BigWave = require('../../assets/images/moderators/BigWave.png')

const ModeratorCustomHeaderMobile = ({ moderator, isLoading }) => {
  const { width } = useWindowDimensions()

  return (
    <ImageBackground
      source={BigWave}
      style={{ width: '100%', height: 128, marginBottom: 50 }}>
      <HStack
        pb={2}
        flex={1}
        px={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Box width={width / 3} mt={6}>
          <BackButton color="white" customText="Back" />
        </Box>
        <Box
          position="relative"
          top={70}
          alignItems="center"
          width={width / 3}
          ml={-4}>
          {isLoading ? (
            <VStack justifyContent="center" alignItems="center" p={6}>
              <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
            </VStack>
          ) : (
            <Image
              borderColor={'yellow.500'}
              borderWidth={2}
              source={{ uri: moderator.photoUrl }}
              alt="Moderator photo"
              borderRadius="full"
              width="100px"
              height="100px"
            />
          )}
        </Box>
        <Box width={width / 3} />
      </HStack>
    </ImageBackground>
  )
}

export default ModeratorCustomHeaderMobile
