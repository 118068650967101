import { useQuery } from '@tanstack/react-query'
import { useLegacyApi } from '../providers/LegacyApiProvider'

const useGetProfessionalRoles = () => {
  const { ProfessionalRoleApi } = useLegacyApi()
  const { data: professionalRoles, isLoading } = useQuery({
    queryKey: ['professionalRoles'],
    queryFn: () => ProfessionalRoleApi.getProfessionalRoles(),
    initialData: [],
  })

  return { professionalRoles, isLoading }
}

export default useGetProfessionalRoles
