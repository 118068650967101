import {
  Box,
  Flex,
  Heading,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import useGetProfessionalRoles from '../../hooks/useGetProfessionalRoles'
import useMobileKeyboardListeners from '../../hooks/useMobileKeyboardListeners'
import ChipSelect from '../_shared/form/ChipSelect'
import SearchBar from '../_shared/form/SearchBar'

const ProfessionalRoleForm = ({ value, setValue }) => {
  const { professionalRoles } = useGetProfessionalRoles()
  const { keyboardOpen } = useMobileKeyboardListeners()
  const isDesktop = useDesktopBreakpoint()
  /* State */
  const [mainOptions, setMainOptions] = useState([
    'Veteran',
    'Nurse',
    'Physician',
    'Caregiver',
    'Leader / Manager',
    'Clinical',
    'Student',
    'Engineer',
    'Administrative',
    'Active Military',
    'Military Spouse',
    'Other',
  ])

  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  /* Functions */
  const suggestOtherOptions = () => {
    if (!searchValue) {
      setSearchResults([])
    } else {
      const newResults = professionalRoles.filter((o) => {
        const isAlreadyInMainOptions =
          mainOptions.find((elem) => elem === o) !== undefined

        return (
          !isAlreadyInMainOptions &&
          (o.toLowerCase().includes(searchValue.toLowerCase()) || o === 'OTHER')
        )
      })
      setSearchResults(newResults)
    }
  }

  useEffect(() => {
    suggestOtherOptions()
  }, [searchValue])

  const addToMainOptions = (option) => {
    Keyboard.dismiss()
    setSearchValue('')
    setMainOptions((state) => [...state, option])
    setValue((state) => [...state, option])
  }

  /* Hooks */
  // Add pre-selected other options to main
  useEffect(() => {
    if (!professionalRoles || !professionalRoles.length) return

    if (value && typeof value[0] == 'string') {
      const parsedValues = value.map((item) => {
        const mainOption = mainOptions.find((o) => o === item)
        if (!mainOption) {
          const other = professionalRoles.find((o) => o === item)
          if (other) {
            setMainOptions((state) => [...state, other])
            return other
          }
        }
        return mainOption
      })
      setValue(parsedValues)
    } else {
      value.forEach((option) => {
        if (!mainOptions.find((o) => o === option))
          setMainOptions((state) => [...state, option])
      })
    }
  }, [professionalRoles])

  /* Render */
  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      bounces={false}
      keyboardShouldPersistTaps="always">
      <VStack alignItems="center" mb="16px">
        <Heading w="275px" textAlign="center" mt="2" mb="5">
          What keeps you busy these days?
        </Heading>
        <Text w="250px" textAlign="center" mb="4">
          Select the options that best apply{'\n'}to you.
        </Text>
        {(isDesktop || (!searchValue && !keyboardOpen)) && (
          <Flex mx="4" mb="5">
            <ChipSelect
              value={value}
              setValue={setValue}
              options={mainOptions}
            />
          </Flex>
        )}
        <Box w="100%" px="6">
          <SearchBar
            value={searchValue}
            setValue={setSearchValue}
            placeholder="Search roles"
            handleSubmit={suggestOtherOptions}
          />
          {(isDesktop || !!searchValue) && searchResults.length > 0 && (
            <VStack pt="2" mb="3">
              {searchResults.map(
                (result) =>
                  !mainOptions.includes(result) && (
                    <Pressable
                      py="1"
                      onPress={() => addToMainOptions(result)}
                      key={result}>
                      <Text color="black" fontSize="16px">
                        {result}
                      </Text>
                    </Pressable>
                  )
              )}
            </VStack>
          )}
        </Box>
      </VStack>
    </ScrollView>
  )
}

export default ProfessionalRoleForm
