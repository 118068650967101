import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { StatusBar } from 'native-base'
import { useEffect, useState } from 'react'
import PersonalizationForm from '../../components/onboarding/PersonalizationForm'
import { useGetProfile } from '../../components/profile/queries'
import secureScreen from '../../hooks/authentication/secureScreen'
import useAuthentication from '../../hooks/authentication/useAuthentication'
import FormLayout from '../../layouts/FormLayout'
import { useLegacyApi } from '../../providers/LegacyApiProvider'
import LoadingScreen from '../LoadingScreen'
import { NavigationParams } from '../NavigationScreen'
import BackgroundLayout from './BackgroundLayout'

const PersonalizationScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const {
    MemberProfileApi: { profile },
  } = useLegacyApi()
  const { user } = useAuthentication()
  const { profileComplete, isFetched } = useGetProfile(!!user)
  const [profileData, setProfileData] = useState(null)

  const getProfileData = async () => {
    const userProfile = await profile()
    setProfileData(userProfile)
  }

  // Get profile data
  useEffect(() => {
    getProfileData()
  }, [])

  useEffect(() => {
    if (profileData) {
      if (!profileData.organization) {
        navigation.navigate('VerifyEligibility')
      }
    }

    if (profileComplete && isFetched) {
      navigation.navigate('Home')
    }
  }, [profileData, profileComplete])

  return (
    <>
      {profileData ? (
        <BackgroundLayout>
          <FormLayout autoBackButton={false}>
            <StatusBar barStyle="dark-content" />
            <PersonalizationForm
              key={JSON.stringify(profileData)}
              profileData={profileData}
            />
          </FormLayout>
        </BackgroundLayout>
      ) : (
        <LoadingScreen />
      )}
    </>
  )
}

export default secureScreen(PersonalizationScreen)
