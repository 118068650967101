import { IconButton } from 'native-base'
import FaIcon from '../_shared/FaIcon'
import BadgeContainer from '../_shared/notification/BadgeContainer'

const RoomAction = ({ action }) => {
  /* State */

  /* Functions */
  function darkenColor(color: string, amount: number) {
    const splitColor = color.split('.')
    const colorName = splitColor[0]
    const shade = Number.parseInt(splitColor[1])

    if (shade === 50) amount -= 50 // Handle colorName.50
    return colorName + '.' + (shade + amount)
  }

  /* Render */
  // Set default colors
  if (action.isMain) {
    action.bg = action.bg ?? 'error.500'
    action.activeBg = action.activeBg ?? 'muted.100'
    action.color = action.color ?? 'text.50'
    action.activeColor = action.activeColor ?? 'text.900'
  }

  return (
    <>
      {action.isMain ? (
        <IconButton
          borderRadius="full"
          variant="solid"
          bg={action.isActive ? action.activeBg : action.bg}
          _hover={{
            bg: action.isActive
              ? darkenColor(action.activeBg, 100)
              : darkenColor(action.bg, 100),
          }}
          _pressed={{
            bg: action.isActive
              ? darkenColor(action.activeBg, 200)
              : darkenColor(action.bg, 200),
          }}
          icon={
            <FaIcon
              name={
                action.activeIcon && action.isActive
                  ? action.activeIcon
                  : action.icon
              }
              color={action.isActive ? action.activeColor : action.color}
              size={20}
              isSolid={action.isSolid}
            />
          }
          onPress={() => {
            action.handlePress()
            if (action.afterAction) action.afterAction()
          }}
        />
      ) : (
        <BadgeContainer text={action.badge}>
          <IconButton
            variant="ghost"
            borderRadius="full"
            _hover={{
              bg: action.isActive
                ? 'secondary.500:alpha.10'
                : 'text.50:alpha.10',
            }}
            _pressed={{
              bg: action.isActive
                ? 'secondary.500:alpha.20'
                : 'text.50:alpha.20',
            }}
            icon={
              <FaIcon
                name={
                  action.activeIcon && action.isActive
                    ? action.activeIcon
                    : action.icon
                }
                color={action.isActive ? 'secondary.500' : 'text.50'}
                size={20}
              />
            }
            onPress={action.handlePress}
          />
        </BadgeContainer>
      )}
    </>
  )
}

export default RoomAction
