import { useQuery } from '@tanstack/react-query'
import { useLegacyApi } from '../../providers/LegacyApiProvider'

const downloadCsv = (jsonArray, filename = 'output.csv') => {
  if (!jsonArray || jsonArray.length === 0) {
    console.warn('Empty JSON data.')
    return
  }

  // Function to escape CSV values
  const escapeCSVValue = (value) => {
    if (typeof value === 'string') {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""')
      // Wrap in double quotes if it contains commas, quotes, or newlines
      if (value.includes(',') || value.includes('"') || value.includes('\n')) {
        value = `"${value}"`
      }
    }
    return value
  }

  // Get headers
  const csvHeader = Object.keys(jsonArray[0]).join(',') + '\n'

  // Process rows
  const csvRows = jsonArray
    .map((row) => Object.values(row).map(escapeCSVValue).join(','))
    .join('\n')

  // Final CSV content
  const csvContent = csvHeader + csvRows

  // Create a Blob and download it
  const blob = new Blob([csvContent], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  URL.revokeObjectURL(url)
}

export const useGetOrganizations = () => {
  const { OrganizationApi } = useLegacyApi()
  const { isLoading, refetch } = useQuery({
    queryKey: ['Report', 'Organization'],
    queryFn: () => OrganizationApi.getOrganizations(),
    enabled: false,
  })

  return {
    isLoading,
    fetchOrganizationNames: refetch,
  }
}

export const useGetMemberReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'Member'],
    queryFn: () => ReportApi.member(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'members'),
  })

  return {
    isLoadingMemberReport: isFetching,
    fetchMemberReport: refetch,
  }
}

export const useGetParticipationReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'Participation'],
    queryFn: () =>
      ReportApi.participation(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'participation'),
  })

  return {
    isLoadingParticipationReport: isFetching,
    fetchParticipationReport: refetch,
  }
}

export const useGetContentUtilizationReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'ContentUtilization'],
    queryFn: () =>
      ReportApi.contentUtilization(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'contentUtilization'),
  })

  return {
    isLoadingContentUtilizationReport: isFetching,
    fetchContentUtilizationReport: refetch,
  }
}

export const useGetMentalHealthMinutesReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'MentalHealthMinutes'],
    queryFn: () =>
      ReportApi.mentalHealthMinutes(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'mentalHealthMinutes'),
  })

  return {
    isLoadingMentalHealthMinutesReport: isFetching,
    fetchMentalHealthMinutesReport: refetch,
  }
}

export const useGetVibeCheckDataReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'VibeCheckData'],
    queryFn: () =>
      ReportApi.vibeCheckData(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'vibeCheckData'),
  })

  return {
    isLoadingVibeCheckDataReport: isFetching,
    fetchVibeCheckDataReport: refetch,
  }
}

export const useGetEmailLeadsReport = (startDate: Date, endDate: Date) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'EmailLeads'],
    queryFn: () => ReportApi.emailLeads(startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data, 'emailLeads'),
  })

  return {
    isLoadingEmailLeadsReport: isFetching,
    fetchEmailLeadsReport: refetch,
  }
}

export const useGetLeaderboardReport = (
  organizationNameList: any[],
  startDate: Date,
  endDate: Date
) => {
  const { ReportApi } = useLegacyApi()
  const { isFetching, refetch } = useQuery({
    queryKey: ['Report', 'Leaderboard'],
    queryFn: () =>
      ReportApi.leaderboard(organizationNameList, startDate, endDate),
    enabled: false,
    onSuccess: (data) => downloadCsv(data.rankings, 'leaderboard'),
  })

  return {
    isLoadingLeaderboardReport: isFetching,
    fetchLeaderboardReport: refetch,
  }
}
