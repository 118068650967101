import { useNavigation } from '@react-navigation/native'
import { Box, FlatList, Flex, Text } from 'native-base'
import { useMemo, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import useParseActivity from '../../hooks/useParseActivity'
import ActivityCard from '../_shared/card/ActivityCard'
import { SkeletonLoader, SkeletonLoaderSize } from '../_shared/SkeletonLoader'
import ActivityModalPreview from './activities/ActivityModalPreview'
import { useActivityCardSearch } from './queries'

const SearchResults = ({
  searchValue,
  timeFilters,
  categoryFilters,
  moderatorsFilters,
}) => {
  const { data, fetchNextPage, isFetching, hasNextPage } =
    useActivityCardSearch(
      searchValue,
      timeFilters,
      categoryFilters,
      moderatorsFilters
    )
  const allData = useMemo(
    () =>
      data?.pages
        .flat()
        .map((page) => page.content)
        .flat(),
    [data]
  )
  const isMobile = useMobileBreakpoint()
  const [modalActivityData, setModalActivityData] = useState(null)
  const [modalActivityShown, setModalActivityShown] = useState(false)
  const handleCloseModal = () => setModalActivityShown(false)
  const navigation = useNavigation()
  const parsedResults = allData?.map((item) => useParseActivity(item))
  const { height } = useWindowDimensions()
  const flatListHeight = height - height * 0.25

  const onPressActivity = (activity) => {
    if (isMobile) {
      setModalActivityData(activity)
      setModalActivityShown(true)
    } else {
      navigation.navigate('Activity', {
        activityId: activity.id,
      })
    }
  }

  return (
    <>
      {isFetching && !parsedResults && (
        <Box safeAreaBottom pt="16px" px="24px">
          {[...Array(1)].map((e, i) => (
            <Box pb="16px" key={i}>
              <SkeletonLoader key={i} size={SkeletonLoaderSize.ACTIVITY_CARD} />
            </Box>
          ))}
        </Box>
      )}
      {parsedResults && (
        <FlatList
          pt="16px"
          px="24px"
          data={parsedResults}
          keyExtractor={(item) => item.id}
          height={flatListHeight}
          onEndReachedThreshold={0.1}
          onEndReached={() => {
            if (hasNextPage && !isFetching) {
              fetchNextPage()
            }
          }}
          ListEmptyComponent={() =>
            !isFetching && (
              <Flex
                bg="primary.50"
                borderRadius="10px"
                p="5"
                my="5"
                overflow="hidden">
                <Text fontSize="sm" textAlign="center">
                  Looks like nothing matches those filters
                </Text>
              </Flex>
            )
          }
          renderItem={({ item }) => {
            return (
              <Box pb="16px">
                <ActivityCard
                  activity={item}
                  onPress={() => onPressActivity(item)}
                />
              </Box>
            )
          }}
          ListFooterComponent={
            isFetching ? (
              <Box safeAreaBottom>
                {[...Array(10)].map((e, i) => (
                  <Box pb="16px" key={i}>
                    <SkeletonLoader
                      key={i}
                      size={SkeletonLoaderSize.ACTIVITY_CARD}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box h="16px" />
            )
          }
        />
      )}
      <ActivityModalPreview
        modalData={modalActivityData}
        modalShown={modalActivityShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
        }}
      />
    </>
  )
}

export default SearchResults
