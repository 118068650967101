import { Box, Flex, HStack, IconButton, Text } from 'native-base'
import { GroupTypes } from '../../../../../domain/models/interfaces/activityInterfaces'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useCardData from '../../../hooks/useCardData'
import CabanaCardBorder from '../card/CabanaCardBorder'
import TodayBadge from '../card/TodayBadge'
import FaIcon from '../FaIcon'

const AbstractCard = ({
  leftComponent,
  title,
  description,
  activity = null,
  reduceOpacityOnComplete,
  borderStyle = {},
  hasShadow,
  type = null,
  onPress = () => {},
  hasTrashIcon = false,
  deleteOnPress = null,
  width = null,
}) => {
  const { cardBorderStateData, typeData } =
    activity != null
      ? useCardData(activity.type, activity.cardBorderState)
      : { cardBorderStateData: {}, typeData: {} }
  const isGroup = GroupTypes.includes(activity?.type)

  return activity != null ? (
    <CabanaCardBorder
      width={width}
      hasShadow={hasShadow}
      borderStyle={borderStyle}
      id={activity.id}
      height="88px"
      cardBorderState={activity.cardBorderState}
      cardBorderStateData={cardBorderStateData}
      reduceOpacityOnComplete={reduceOpacityOnComplete}
      onPress={onPress}>
      <CardLayout
        type={type}
        leftComponent={leftComponent}
        title={title}
        description={description}
        startDate={activity.startDate}
        typeData={typeData}
        isGroup={isGroup}
        hasTrashIcon={hasTrashIcon}
        deleteOnPress={deleteOnPress}
      />
    </CabanaCardBorder>
  ) : (
    <Box
      borderRadius={10}
      bgColor="white"
      borderColor="grey.200"
      borderWidth={1}>
      <CardLayout
        type={type}
        leftComponent={leftComponent}
        title={title}
        description={description}
        hasTrashIcon={hasTrashIcon}
        deleteOnPress={deleteOnPress}
      />
    </Box>
  )
}

const CardLayout = ({
  leftComponent,
  title,
  description,
  startDate,
  typeData,
  isGroup = false,
  type = null,
  hasTrashIcon = false,
  deleteOnPress = null,
}) => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <HStack space="4" p="4" borderRadius={10}>
      {leftComponent}
      <Flex flex="1" justify="space-evenly" bg="white">
        <Text
          textTransform="capitalize"
          fontSize="14px"
          fontWeight={type === 'history' ? 400 : 'medium'}
          color="text.900"
          lineHeight="18px"
          numberOfLines={2}
          bg="white"
          mb={type === 'history' ? 1 : 0}>
          {title}
        </Text>
        <HStack alignItems="center">
          {isGroup && <TodayBadge date={startDate} typeData={typeData} />}
          <Text
            fontSize={isGroup ? '10px' : '12px'}
            color="text.500"
            lineHeight="14px"
            numberOfLines={2}
            bg="white">
            {`${description} ${isGroup ? `• ${typeData.name}` : ''}`}
          </Text>
        </HStack>
      </Flex>
      {hasTrashIcon && (
        <IconButton
          position="absolute"
          top={isDesktop ? '30%' : '50%'}
          right={4}
          borderRadius="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="muted.300"
          w="32px"
          h="32px"
          icon={<FaIcon name="trash-can" color="muted.700" size={16} />}
          onPress={deleteOnPress}
        />
      )}
    </HStack>
  )
}

export default AbstractCard
