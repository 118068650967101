import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, Text, VStack } from 'native-base'
import { NavigationParams } from '../../screens/NavigationScreen'

const ShareCabana = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  return (
    <VStack
      borderRadius="20px"
      py={5}
      px={4}
      mx={5}
      mt={5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      <Text mb={4} color="primary.900" fontSize="16px" fontWeight={700}>
        Refer a Coworker
      </Text>
      <Text color="primary.900" px={3} alignSelf="center" textAlign="center">
        Recommend Cabana to a coworker and earn rewards once they’ve signed up!
      </Text>
      <Button mt={6} onPress={() => navigation.navigate('Referral')}>
        <Text color="white" fontSize="16px">
          Get started
        </Text>
      </Button>
    </VStack>
  )
}

export default ShareCabana
