import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, FlatList, Heading, HStack, Text } from 'native-base'
import { Pressable, useWindowDimensions } from 'react-native'
import { Group } from '../../../../domain/models/interfaces/activityInterfaces'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
  useTabletBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'
import GroupCard from '../_shared/card/GroupCard'

const LiveAndUpcoming = ({ data, onPressActivity }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const isTablet = useTabletBreakpoint()
  const { width } = useWindowDimensions()
  const parsedData = isDesktop ? data.slice(0, 3) : data
  const margin = isTablet ? 40 : 24
  const marginInPx = margin + 'px'
  const numberOfVisibleCards = isTablet ? 2 : 1
  const cardWidth =
    (width - margin * 2 - (isTablet && 8)) / numberOfVisibleCards

  return (
    <Box>
      <HStack justifyContent="space-between">
        <Heading size="sm" color="primary.900">
          Live and Upcoming Groups
        </Heading>
        <Pressable onPress={() => navigation.navigate('LiveGroups')}>
          <Text color="primary.900">see all</Text>
        </Pressable>
      </HStack>
      <FlatList
        horizontal
        mx={'-' + marginInPx}
        showsHorizontalScrollIndicator={false}
        data={parsedData}
        keyExtractor={(activity: Group) => activity.id.toString()}
        renderItem={({ item: activity, index }) => {
          return (
            <Box
              ml={
                index === 0 ? marginInPx : isTablet || isMobile ? '4px' : '8px'
              }
              mr={
                index === data.length - 1
                  ? marginInPx
                  : isTablet || isMobile
                  ? '4px'
                  : '8px'
              }
              mt={4}
              key={activity.id}>
              <GroupCard
                testID={`live-group-button-${index}`}
                activity={activity}
                width={isDesktop ? '304px' : cardWidth}
                onPress={() => onPressActivity(activity)}
              />
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default LiveAndUpcoming
