import { useMutation } from '@tanstack/react-query'
import { useLegacyApi } from '../../providers/LegacyApiProvider'

export const useRequestOchsnerAccessCode = (
  onSuccessCallback,
  onErrorCallback
) => {
  const { MemberSignupApi } = useLegacyApi()
  const { mutate, isLoading } = useMutation(MemberSignupApi.ochsnerAccessCode, {
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  })
  return { requestCode: mutate, isLoading }
}
