import { Button, Text, View } from 'native-base'
import { useEffect } from 'react'
import { Linking } from 'react-native'
import CounterReferrals from '../components/referral/CounterReferrals'
import Rewards from '../components/referral/Rewards'
import useCustomerIo from '../hooks/customerio/useCustomerIo'

const ReferralScreen = () => {
  const customerIo = useCustomerIo()

  useEffect(() => {
    customerIo.track('Referrals Reached')
  }, [])

  return (
    <View ml={-5}>
      <CounterReferrals />
      <Rewards />
      <View>
        <Text color="text.500" fontSize="12px" textAlign="center">
          Got questions?
        </Text>
        <Button
          variant="link"
          onPress={() => {
            Linking.openURL(
              'https://help.yourcabana.com/en/articles/8119307-what-is-cabana-s-referral-program'
            )
          }}>
          <Text
            fontSize="12px"
            color="secondary.600"
            textAlign="center"
            textDecoration="underline"
            mt={-3}>
            Learn more about this program
          </Text>
        </Button>
      </View>
    </View>
  )
}

export default ReferralScreen
