import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { AlertDialog, Button, Heading, HStack, Text } from 'native-base'
import { useCallback, useState } from 'react'
import { groupDateFormat } from '../../../../../domain/models/interfaces/activityInterfaces'
import { useLegacyApi } from '../../../providers/LegacyApiProvider'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'

const CancelGroupButton = ({ activity }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const { userProfile } = useUserProfileContext()
  const { ActivityApi } = useLegacyApi()

  const isCancelable = useCallback(() => {
    const now = new Date()

    return (
      userProfile.admin &&
      !!activity.startDate && // isGroup
      activity.startDate < now &&
      activity.endDate > now &&
      !activity.started &&
      !activity.ended
    )
  }, [activity])

  const cancelGroup = () => {
    ActivityApi.cancel(activity.id).then(() => {
      window.location.reload()
    })
  }

  const button = (
    <Button
      variant={'outline'}
      alignSelf={'center'}
      onPress={() => setConfirmationOpen(true)}>
      <HStack space={2} alignItems={'center'}>
        <FontAwesomeIcon icon={'fa-regular fa-ban'} color={'red'} />
        <Text>Cancel this group</Text>
      </HStack>
    </Button>
  )

  const dialog = (
    <AlertDialog
      isOpen={confirmationOpen}
      leastDestructiveRef={undefined}
      size={'lg'}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton onPress={() => setConfirmationOpen(false)} />
        <AlertDialog.Header>
          <HStack space={2} alignItems={'center'}>
            <FontAwesomeIcon
              icon={'fa-regular fa-ban'}
              color={'red'}
              size={24}
            />
            <Heading>Cancel this group</Heading>
          </HStack>
        </AlertDialog.Header>
        <AlertDialog.Body>
          <Text fontSize={'lg'}>
            This will cancel "{activity.title}"
            <br />
            with {activity.moderator.name}
            <br />
            at {groupDateFormat(activity.startDate)}.<br />
            Canceling a group cannot be undone.
          </Text>
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="solid" onPress={() => cancelGroup()}>
              Cancel the group
            </Button>
            <Button variant="ghost" onPress={() => setConfirmationOpen(false)}>
              Go back
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  )

  return (
    <>
      {isCancelable() ? (
        <>
          {button}
          {dialog}
        </>
      ) : null}
    </>
  )
}

export default CancelGroupButton
