import { Button, Text } from 'native-base'
import { useCallback } from 'react'
import { Linking, Platform, Share } from 'react-native'
import Configuration from '../../../../../domain/services/ConfigurationService'
import useCustomerIo from '../../../hooks/customerio/useCustomerIo'

const ShareButton = ({
  activity,
  buttonStyle = {},
  noMargin = false,
  isModerator = false,
}) => {
  const customerIo = useCustomerIo()

  const eventName = activity.title
  const eventDate = new Date(activity.startDate).toLocaleDateString()
  const eventTime = new Date(activity.startDate).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  })
  const eventLocation = `${Configuration.CABANA_UI}/activity?activityId=${activity.id}`
  const eventDescription = activity.description

  const shareAnalytics = () => {
    customerIo.track('Group Shared', {
      Description: eventDescription,
      'Detail Link': eventLocation,
      ID: activity.id,
      Duration: activity.duration,
      Moderator: activity.moderator.name,
      Title: eventName,
      Type: activity.type,
      Start: `${eventDate} - ${eventTime}`,
    })
  }

  const handleShare = useCallback(async () => {
    const message = `📅 ${eventName}\n⏰ ${eventDate} at ${eventTime}\n📍 ${eventLocation}\n📝 ${eventDescription}\n\nTake a moment to pause, breathe, and reconnect. Join me at ${eventName} on ${eventDate} at ${eventTime}.`

    try {
      const response = await Share.share({
        message,
      })
      if (response.action === Share.sharedAction) {
        shareAnalytics()
      }
    } catch (err) {
      console.debug(err)
    }
  }, [activity])

  const handleSendEmail = useCallback(async () => {
    const subject = `Join me at ${eventName}`
    const body = `📅 ${eventName}\n⏰ ${eventDate} at ${eventTime}\n📍 ${eventLocation}\n📝 ${eventDescription}\n\nTake a moment to pause, breathe, and reconnect. Join me at ${eventName} on ${eventDate} at ${eventTime}.`
    const encodedSubject = encodeURIComponent(subject)
    const encodedBody = encodeURIComponent(body)
    const encoded = `mailto:?subject=${encodedSubject}&body=${encodedBody}`
    const supported = await Linking.canOpenURL(encoded)
    if (supported) {
      shareAnalytics()
      await Linking.openURL(encoded)
    } else {
      console.debug('Cannot open email')
    }
  }, [activity])

  return (
    <Button
      style={buttonStyle}
      mt={noMargin ? 0 : 4}
      mb={isModerator ? 4 : 0}
      variant="outline"
      onPress={Platform.OS === 'ios' ? handleShare : handleSendEmail}>
      <Text fontSize="md" fontWeight={500} color="primary.700">
        Share
      </Text>
    </Button>
  )
}

export default ShareButton
