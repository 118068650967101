import { Center, Flex, Text } from 'native-base'
import { useEffect, useRef } from 'react'
import { useWindowDimensions } from 'react-native'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import { NO_CAMERA } from '../../../../domain/models/interfaces/camera'
import { useCamerasContext } from '../../providers/CameraProvider'
import { useRoomContext } from '../../providers/Room30Provider'
import FaIcon from '../_shared/FaIcon'

const NoCamera = ({ me }) => (
  <Center position="absolute" bg="#262626" width="100%" height="100%">
    <Center>
      <FaIcon name="video-slash" size={28} color="text.50" />
      <Text color="text.50" ml="8px" fontSize="sm" mt="8px">
        {me?.role === AttendeeRole.Moderator
          ? 'Your camera is off'
          : 'Moderator camera is off'}
      </Text>
    </Center>
  </Center>
)

const VideoFeed = ({ height = '360px', borderRadius = '6' }) => {
  const { width } = useWindowDimensions()
  const { video, participants, me, screenTrack } = useRoomContext()
  const { selectedCamera } = useCamerasContext()

  const mainVideo = useRef<HTMLVideoElement>(null)
  const nestedVideo = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (me.role === AttendeeRole.Moderator) {
      const attach = (videoRef, track: MediaStreamTrack) => {
        if (mainVideo.current?.srcObject) {
          mainVideo.current.srcObject = null
        }
        if (nestedVideo.current?.srcObject) {
          nestedVideo.current.srcObject = null
        }

        const stream = new MediaStream([track])
        videoRef.current.srcObject = stream
      }

      if (selectedCamera && selectedCamera !== NO_CAMERA) {
        navigator.mediaDevices
          .getUserMedia({
            video: { deviceId: selectedCamera.object.deviceId },
          })
          .then((stream) => {
            const track = stream.getVideoTracks()[0]
            attach(me.screenShare ? nestedVideo : mainVideo, track)

            if (me.screenShare && screenTrack?.mediaStreamTrack) {
              const screenStream = new MediaStream([
                screenTrack.mediaStreamTrack,
              ])
              mainVideo.current.srcObject = screenStream
            }
          })
      }
    }
  }, [selectedCamera, me.screenShare, screenTrack?.mediaStreamTrack])

  useEffect(() => {
    if (me.role === AttendeeRole.Moderator) return

    const cameraStream = video.find((v) => v.type === 'camera')
    const screenStream = video.find((v) => v.type === 'screen')

    if (screenStream) {
      screenStream.attach(mainVideo.current)

      if (cameraStream) {
        cameraStream.attach(nestedVideo.current)
      }
    } else if (cameraStream) {
      cameraStream.attach(mainVideo.current)
    }

    return () => {
      if (cameraStream) {
        cameraStream.detach()
      }
      if (screenStream) {
        screenStream.detach()
      }
    }
  }, [video])

  const videoWidth = width <= 1350 && width >= 991 ? width - 715 : 640

  const isModerator = participants.find(
    (participant) => participant.role === AttendeeRole.Moderator
  )

  const showMainCameraOffMessage =
    !isModerator?.camera &&
    ((me.role === AttendeeRole.Moderator && !me.screenShare) ||
      (me.role !== AttendeeRole.Moderator &&
        !video.find((v) => v.type === 'screen')))

  return (
    <Flex
      maxWidth={videoWidth}
      h={height}
      bg="black"
      borderRadius={borderRadius}>
      <video
        ref={mainVideo}
        playsInline
        autoPlay
        muted
        style={{
          width: '100%',
          height: height,
          borderRadius: borderRadius,
          objectFit: 'cover',
          transform:
            me.role === AttendeeRole.Moderator && !me.screenShare
              ? 'scaleX(-1)'
              : 'none',
        }}
      />
      {showMainCameraOffMessage && <NoCamera me={me} />}
      <Flex
        borderRadius={6}
        position="absolute"
        top="8px"
        right="8px"
        bg="muted.900"
        opacity={
          (me.role === AttendeeRole.Moderator && me.screenShare) ||
          (me.role !== AttendeeRole.Moderator &&
            video.find((v) => v.type === 'screen'))
            ? '1'
            : '0'
        }>
        <Center my="auto">
          <video
            ref={nestedVideo}
            playsInline
            autoPlay
            muted
            style={{
              maxWidth: 160,
              maxHeight: 90,
              borderRadius: 6,
              transform:
                me.role === AttendeeRole.Moderator ? 'scaleX(-1)' : 'none',
            }}
          />
          {!isModerator?.camera && <NoCamera me={me} />}
        </Center>
      </Flex>
    </Flex>
  )
}

export default VideoFeed
