import { Flex } from 'native-base'
import SearchBar from '../_shared/form/SearchBar'

const SearchBarSection = ({
  searchValue,
  onModifyText,
  handleSubmit,
  handleFocus,
  handleCancel,
  route,
  routeParsedTimeFilters,
  routeParsedCategoryFilters,
  isFilterModalOpen,
}) => (
  <Flex
    m="5"
    bg="white"
    borderRadius={4}
    borderColor="muted.300"
    borderWidth="1px">
    <SearchBar
      value={searchValue}
      setValue={onModifyText}
      handleSubmit={handleSubmit}
      placeholder="Live groups, topics, activities"
      handleFocus={handleFocus}
      handleCancel={handleCancel}
      autoFocused={
        !route.params?.searchValue &&
        routeParsedTimeFilters.length === 0 &&
        routeParsedCategoryFilters.length === 0
      }
      showCancel
      isBeingFiltered={isFilterModalOpen}
    />
  </Flex>
)

export default SearchBarSection
