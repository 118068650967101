import {
  Alert,
  Button,
  Divider,
  FormControl,
  Heading,
  Input,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import secureScreen from '../../hooks/authentication/secureScreen'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'
import FormLayout from '../../layouts/FormLayout'
import { useLegacyApi } from '../../providers/LegacyApiProvider'
import BackgroundLayout from './BackgroundLayout'

const VerifyEligibilityScreen = ({ navigation }) => {
  const customerIo = useCustomerIo()

  const [email, setEmail] = useState('')
  const [emailDirty, setEmailDirty] = useState(false)
  const [verifyError, setVerifyError] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const { MemberSignupApi } = useLegacyApi()

  const verifyEmail = () => {
    setVerifying(true)
    MemberSignupApi.requestAccessCode(email)
      .then(() => {
        customerIo.track('Access Code Requested', {
          Email: email,
        })
        navigation.navigate('VerifyAccessCode', {
          email,
          codeEmailed: true,
        })
      })
      .catch((e) => {
        console.debug(e)
        setVerifyError(true)
        setEmailDirty(false)
      })
      .finally(() => {
        setVerifying(false)
      })
  }

  useEffect(() => {
    setEmailDirty(!!email)
  }, [email])

  return (
    <BackgroundLayout>
      <FormLayout bg="white" autoBackButton={false}>
        <VStack space={6}>
          <Heading fontSize={'2xl'} mt="3" color="primary.900">
            Let's get started
          </Heading>
          <Text color="primary.900" fontSize="16px">
            You'll need an access code to create your Cabana account. An access
            code lets us verify that you're part of an organization partnered
            with Cabana.
          </Text>
          <Divider />
          <Text color="primary.900" fontSize="16px">
            We can send an access code to your email address, containing an
            organization-specific domain.
          </Text>
          <FormControl isInvalid={verifyError}>
            <Input
              onChangeText={setEmail}
              bg="white"
              fontSize="16px"
              placeholder="name@organization.com"
            />
            <FormControl.ErrorMessage>
              <Alert
                colorScheme="error"
                variant="subtle"
                color="black"
                fontSize={'sm'}
                fontWeight={'normal'}>
                Uh oh - this email is invalid. Make sure to use your
                organization-issued email and that it’s spelled properly.
              </Alert>
            </FormControl.ErrorMessage>
          </FormControl>
          <Button
            isDisabled={!emailDirty}
            onPress={verifyEmail}
            isLoading={verifying}>
            Email my access code
          </Button>
          <Text color="primary.900" fontSize="18px" textAlign="center">
            - OR -
          </Text>
          <Text color="primary.900" fontSize="16px">
            If your organization provided you with a six-digit access code, you
            can enter it on the next screen.
          </Text>
          <Button
            variant="outline"
            onPress={() =>
              navigation.navigate('VerifyAccessCode', {
                codeEmailed: false,
              })
            }>
            I know my access code
          </Button>
        </VStack>
      </FormLayout>
    </BackgroundLayout>
  )
}

export default secureScreen(VerifyEligibilityScreen)
