import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Box, Button, Flex, HStack, Input, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useLegacyApi } from '../../../providers/LegacyApiProvider'
import BottomSheet from '../../_shared/notification/BottomSheet'
import ProgressModal from '../../_shared/ProgressModal'

const toolbarOptions = {
  options: ['inline', 'link', 'list', 'remove', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered', 'indent'],
  },
}

const MessageAllButtonAndModal = ({ activity, buttonProps = {} }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [sendMessageModalShown, setSendMessageModalShown] = useState(false)
  const [subject, setSubject] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { ModeratorApi } = useLegacyApi()

  const sendMessageHandler = () => {
    const message = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setSendMessageModalShown(false)
    ModeratorApi.messageAll(activity.id, subject, message)
    setShowSuccessMessage(true)
  }

  return (
    <>
      <Button
        variant="outline"
        width="48%"
        ml="4%"
        onPress={() => setSendMessageModalShown(true)}
        {...buttonProps}>
        <Text fontSize="md" fontWeight={500}>
          Message all
        </Text>
      </Button>
      <BottomSheet
        isOpen={sendMessageModalShown}
        handleClose={() => setSendMessageModalShown(false)}
        title="Send message to group"
        body={
          <Box>
            <VStack>
              <HStack>
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  color="text.500"
                  width="56px">
                  From:
                </Text>
                <Text fontWeight="500" fontSize="16px" color="text.800">
                  {activity.moderator.name}
                </Text>
              </HStack>
              <HStack>
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  color="text.500"
                  width="56px">
                  To:
                </Text>
                <Text fontWeight="500" fontSize="16px" color="text.800">
                  All registered participants
                </Text>
              </HStack>
              <Input
                mt="16px"
                placeholder="Subject"
                fontSize="16px"
                onChangeText={setSubject}
                value={subject}
              />
              <Box
                mt="16px"
                borderColor="muted.300"
                borderWidth="1px"
                borderRadius="4px"
                overflow="hidden">
                <Editor
                  editorState={editorState}
                  editorStyle={{
                    backgroundColor: 'white',
                    padding: 8,
                    paddingTop: 0,
                    fontSize: 16,
                    height: 200,
                    fontFamily: 'Arial',
                  }}
                  onEditorStateChange={(newState) => setEditorState(newState)}
                  toolbar={toolbarOptions}
                />
              </Box>
            </VStack>
          </Box>
        }
        bottomComponent={
          <Flex align="flex-end" p="3" pr="16px">
            <Button.Group space={2} alignSelf="flex-end">
              <Button
                variant="outline"
                borderWidth="1px"
                borderColor="muted.300"
                onPress={() => setSendMessageModalShown(false)}>
                <Text fontSize="md" fontWeight={500}>
                  Cancel
                </Text>
              </Button>
              <Button onPress={sendMessageHandler}>
                <Text fontSize="md" fontWeight={500} color="white">
                  Send message
                </Text>
              </Button>
            </Button.Group>
          </Flex>
        }
        hasCloseOnBottom={false}
        width="600px"
        bodyPadding="16px"
      />
      <ProgressModal
        downloadProgress={100}
        isVisible={showSuccessMessage}
        successMessage={
          <Text>
            Your message has been sent to all registered participants.
          </Text>
        }
        animationDuration={5000}
        position={{
          top: 10,
          right: 0,
        }}
        callback={() => {
          setTimeout(() => {
            setShowSuccessMessage(false)
          }, 500)
        }}
        progressMessage={undefined}
      />
    </>
  )
}

export default MessageAllButtonAndModal
