import { useQuery } from '@tanstack/react-query'
import { useLegacyApi } from '../../providers/LegacyApiProvider'

export const useModerators = (sort?: string, limit?: number) => {
  const { ModeratorApi } = useLegacyApi()
  const { data, isLoading } = useQuery(['moderators', sort, limit], () =>
    ModeratorApi.moderators(sort, limit)
  )
  return { data, isLoading }
}

export const useModeratorDetail = (id: string) => {
  const { ModeratorApi } = useLegacyApi()
  const { data, isLoading } = useQuery(['moderator-detail', id], () =>
    ModeratorApi.moderatorDetails(id)
  )
  return { data, isLoading }
}
