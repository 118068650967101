import * as Application from 'expo-application'
import {
  Box,
  ScrollView,
  SectionList,
  StatusBar,
  Text,
  VStack,
} from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'
import useGetProfileActions from '../../hooks/useGetProfileActions'
import { useLegacyApi } from '../../providers/LegacyApiProvider'
import {
  useAllTimeLeaderboard,
  useMonthlyLeaderboard,
} from '../../queries/leaderboardQueries'
import ShareCabana from '../referral/ShareCabana'
import Tabs from '../_shared/layout/Tabs'
import Bookmarks from './Bookmarks'
import EmployersResources from './EmployersResources'
import LatestVibeCheck from './LatestVibeCheck'
import Leaderboard from './Leaderboard/Leaderboard'
import MyGroups from './MyGroups'
import ProfileItem from './ProfileItem'
import WeeklyProgressCircle from './WeeklyProgressCircle'

const ProfileViewMobile = ({ selectedScreen }) => {
  const { ProfileActions } = useGetProfileActions()
  const { EapApi } = useLegacyApi()
  const { data, isLoading } = useAllTimeLeaderboard()
  const { data: monthlyData, isLoading: isLoadingMonthlyData } =
    useMonthlyLeaderboard()

  const [hasEap, setHasEap] = useState(false)
  const [tabSelected, setTabSelected] = useState(
    selectedScreen?.includes('leaderboard') ? 'ACHIEVEMENTS' : 'PROFILE'
  )

  const renderItem = useCallback(({ item, index, section }) => {
    return (
      <ProfileItem
        item={item}
        isFirst={index === 0}
        isLast={index + 1 === section.data.length}
      />
    )
  }, [])

  const TabContainer = ({ children, hasMargintop = true }) => (
    <ScrollView
      showsVerticalScrollIndicator={false}
      flex={1}
      background="muted.50"
      paddingTop={hasMargintop ? 4 : 0}>
      <StatusBar barStyle="dark-content" />
      {children}
      <Box height={16} />
    </ScrollView>
  )

  const ProfileContent = () => (
    <TabContainer>
      <LatestVibeCheck />
      <Bookmarks />
      <MyGroups />
      <ShareCabana />
      {hasEap && <EmployersResources />}
    </TabContainer>
  )

  const AchievementsContent = () => {
    const customerIO = useCustomerIo()
    useEffect(() => {
      customerIO.page('Profile', { subscreen: 'leaderboard' })
    }, [])

    return (
      <TabContainer hasMargintop={false}>
        <Box mt={-8}>
          <WeeklyProgressCircle />
          <Leaderboard
            data={monthlyData}
            type="monthly"
            isLoading={isLoadingMonthlyData}
          />
          <Leaderboard data={data} isLoading={isLoading} />
        </Box>
      </TabContainer>
    )
  }

  const SettingsContent = () => (
    <TabContainer hasMargintop={false}>
      <SectionList
        my={-7}
        bounces={false}
        sections={ProfileActions}
        renderSectionFooter={() => <Box width="100%" height="24px" />}
        renderItem={renderItem}
        ListFooterComponent={() => (
          <Box alignSelf="center">
            <Text ml={2} mb={10}>
              App version {Application.nativeApplicationVersion}, build{' '}
              {Application.nativeBuildVersion}
            </Text>
          </Box>
        )}
      />
    </TabContainer>
  )

  const tabsData = {
    ['PROFILE']: {
      title: 'Profile',
      content: <ProfileContent />,
    },
    ['ACHIEVEMENTS']: {
      title: 'Achievements',
      content: <AchievementsContent />,
    },
    ['SETTINGS']: {
      title: 'Settings',
      content: <SettingsContent />,
    },
  }

  useEffect(() => {
    ;(async () => {
      try {
        const eapPage = await EapApi.getEapPage()
        setHasEap(eapPage.data)
      } catch (e) {
        setHasEap(false)
      }
    })()
  }, [])

  return (
    <VStack safeAreaTop bg="white" flex={1}>
      <Tabs
        hasShadow={false}
        tabSelected={tabSelected}
        selectTab={setTabSelected}
        tabsData={tabsData}
      />
    </VStack>
  )
}

export default ProfileViewMobile
