import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Camera } from '../../../domain/models/interfaces/camera'
import useStorage from '../../../domain/services/useStorage'
import useCameras, { UseCamerasResult } from '../hooks/useCameras'

export interface UseCamerasContext extends UseCamerasResult {
  selectCamera: (camera: Camera) => void
  selectedCamera: Camera | null
  reset: () => void
}

const CamerasContext = createContext<UseCamerasContext | undefined>(undefined)

export const useCamerasContext = (): UseCamerasContext =>
  useContext(CamerasContext)

interface CamerasProviderProps {
  children: ReactNode
}

export const CamerasProvider: React.FC<CamerasProviderProps> = ({
  children,
}) => {
  const { loading, cameras, error, refresh } = useCameras()
  const [savedCamera, setSavedCamera] = useStorage('@camera', null)
  const [selectedCamera, setSelectedCamera] = useState<Camera>(null)

  const selectCamera = (camera: Camera) => {
    setSelectedCamera(camera)
    setSavedCamera(camera)
  }

  const reset = () => {
    setSavedCamera(null)
  }

  useEffect(() => {
    if (cameras.length === 0) return
    if (selectedCamera && selectedCamera === savedCamera) return

    const matchingCamera = cameras.find(
      (camera) => camera.object.deviceId === savedCamera?.object?.deviceId
    )

    selectCamera(matchingCamera ?? cameras[0])
  }, [cameras, savedCamera])

  const context: UseCamerasContext = {
    loading,
    cameras,
    error,
    refresh,
    selectCamera,
    selectedCamera,
    reset,
  }

  return (
    <CamerasContext.Provider value={context}>
      {children}
    </CamerasContext.Provider>
  )
}
