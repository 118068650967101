import { Box, HStack, Image, Pressable, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Platform } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import FaIcon from '../_shared/FaIcon'

const ProfileItem = ({
  item,
  isFirst = false,
  isLast = false,
  onPress = null,
  isSelected = false,
  isIconSolid = false,
}) => {
  const isDesktop = useDesktopBreakpoint()
  const [width, setWidth] = useState(0)

  return (
    <Pressable
      mx={!isDesktop ? 5 : 0}
      height="56px"
      onPress={onPress ? onPress : item.action}
      mb={-1}>
      <HStack
        flexGrow={0}
        onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
        borderTopRadius={isFirst ? '20px' : 0}
        borderBottomRadius={isLast ? '20px' : 0}
        borderColor="grey.200"
        borderWidth={isDesktop ? 0 : 1}
        height="100%"
        width="100%"
        backgroundColor={isSelected ? 'primary.50' : 'white'}
        alignItems="center"
        justifyContent="space-between"
        borderTopWidth={(!isFirst || !isDesktop) && '1px'}
        borderColor="muted.300">
        <HStack w={`${width - 60}px`}>
          {item.icon && (
            <Box justifyContent="center" alignItems="center" ml="14px">
              <FaIcon
                name={item.icon}
                size={20}
                color="primary.900"
                isSolid={isIconSolid}
              />
            </Box>
          )}
          {item.iconImage && (
            <Box
              justifyContent="center"
              alignItems="center"
              ml="14px"
              mr="-2px"
              style={
                Platform.OS === 'ios'
                  ? {
                      shadowOffset: {
                        width: 0,
                        height: 4,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 4,
                      shadowColor: '#000000',
                    }
                  : {}
              }>
              <Image
                source={item.iconImage}
                alt="Intercom icon"
                height="22px"
                width="22px"
              />
            </Box>
          )}
          <VStack ml="14px" w={`${width - 60}px`}>
            {item.description && (
              <Text fontWeight="500" color="muted.400">
                {item.description}
              </Text>
            )}
            <Text
              color="primary.900"
              fontWeight={isSelected ? 'bold' : '500'}
              numberOfLines={1}
              mr="15px">
              {item.type === 'password' ? '**********' : item.name}
            </Text>
          </VStack>
        </HStack>
        {item.mutedRightIcon ? (
          <Box mr="14px" backgroundColor="muted.200" p="8px" borderRadius="45">
            <FaIcon name={item.mutedRightIcon} size={20} />
          </Box>
        ) : (
          item.hasRightIcon && (
            <Box mr="14px">
              <FaIcon
                name={item.rightIcon ?? 'chevron-right'}
                size={20}
                color="primary.700"
              />
            </Box>
          )
        )}
      </HStack>
    </Pressable>
  )
}

export default ProfileItem
