import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Heading, HStack, Pressable, Text, VStack } from 'native-base'
import { FlatList } from 'react-native'
import { ModeratorsExploreProps } from '../../../../domain/models/interfaces/explore/moderatorsInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'
import ModeratorAvatar from '../moderators/ModeratorAvatar'

const ModeratorsExplore = ({ data }: ModeratorsExploreProps) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack>
      <HStack justifyContent="space-between" width="100%" alignSelf="center">
        <Heading size="sm" color="primary.900" mb={4}>
          Moderators
        </Heading>
        <Pressable onPress={() => navigation.navigate('Moderators')}>
          <Text color="primary.900" mr={isDesktop ? 0 : 4}>
            see all
          </Text>
        </Pressable>
      </HStack>
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal
        data={data}
        renderItem={({ item }) => <ModeratorAvatar moderator={item} />}
        keyExtractor={(item) => item.id}
      />
    </VStack>
  )
}

export default ModeratorsExplore
