import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Notifications from 'expo-notifications'
import { useEffect } from 'react'
import { Alert, Linking } from 'react-native'

const FOURTEEN_DAYS = 14 * 24 * 60 * 60 * 1000

const PushNotificationPrompt = () => {
  useEffect(() => {
    const checkNotificationPrompt = async () => {
      const isPushEnabled = await checkPushNotifications()
      const lastPrompt = await AsyncStorage.getItem('lastPushPrompt')
      const currentDate = new Date()

      const lastPromptDate =
        lastPrompt && !isNaN(new Date(lastPrompt).getTime())
          ? new Date(lastPrompt)
          : new Date(0)

      const timeDifference = currentDate.getTime() - lastPromptDate.getTime()

      if (!isPushEnabled && timeDifference > FOURTEEN_DAYS) {
        Alert.alert(
          'Cabana Would Like to Send You Notifications',
          'Notifications may include alerts, sounds, and icon badges - to remind you of your upcoming groups, new content, and other reminders.',
          [
            {
              text: "Don't Allow",
              style: 'cancel',
            },
            {
              text: 'Allow',
              onPress: () => {
                AsyncStorage.setItem('lastPushPrompt', new Date().toISOString())
                Linking.openURL('app-settings:')
              },
            },
          ]
        )
      }
    }

    checkNotificationPrompt()
  }, [])

  const checkPushNotifications = async () => {
    const { status } = await Notifications.getPermissionsAsync()
    return status === 'granted'
  }

  return null
}

export default PushNotificationPrompt
