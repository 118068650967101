import { useQuery } from '@tanstack/react-query'
import { useLegacyApi } from '../providers/LegacyApiProvider'

export const useCollections = () => {
  const { CollectionApi } = useLegacyApi()

  const { data, isLoading } = useQuery({
    queryKey: ['Collections'],
    queryFn: () => CollectionApi.collections(),
  })

  return {
    data,
    isLoading,
  }
}

export const useCollection = (slug: string) => {
  const { CollectionApi } = useLegacyApi()

  const { data, isLoading } = useQuery({
    queryKey: ['Collection', slug],
    queryFn: () => CollectionApi.collection(slug),
  })

  return {
    data,
    isLoading,
  }
}
