import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { format } from 'date-fns'
import { Flex, HStack, IconButton, Text, View, VStack } from 'native-base'
import { useState } from 'react'
import Modal from 'react-native-modal'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'
import VibeCheckScreen from '../../screens/VibeCheckScreen'
import EmptyListComponent from '../EmptyListComponent'
import moods from '../vibeCheck/Moods'
import { useVibeCheckHistory } from '../vibeCheck/queries'
import FaIcon from '../_shared/FaIcon'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import WeeklyVibeCheck from './WeeklyVibeCheck'

const EmptyImage = require('../../assets/images/vibe-check/empty-vibe-check.png')

const LatestVibeCheck = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { data, isLoading } = useVibeCheckHistory()
  const isDesktop = useDesktopBreakpoint()

  const vibeCheckHistory = data?.pages[0].content
  const lastVibeCheck = data?.pages[0].content[0]
  const mood = moods.find((x) => x.name === lastVibeCheck?.mood)

  const [isModalVisible, setModalVisible] = useState(false)

  const toggleModal = () => {
    setModalVisible(!isModalVisible)
  }

  const handlePress = () => {
    if (isDesktop) {
      toggleModal()
    } else {
      navigation.navigate('VibeCheck')
    }
  }

  return (
    <HStack
      borderRadius="20px"
      p={4}
      mx={isDesktop ? 0 : 5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      {isLoading ? (
        <SkeletonLoader size={{ width: '100%', height: 150 }} />
      ) : vibeCheckHistory?.length > 0 ? (
        <VStack>
          <View flexDirection="row" justifyContent="space-between" mb={4}>
            <Text color="primary.900" fontSize="16px" fontWeight={700} flex={1}>
              Latest Vibe Check
            </Text>
            <IconButton
              m={-2}
              borderRadius="full"
              onPress={() => handlePress()}
              icon={<FaIcon name="plus" size={20} color="primary.900" />}
            />
          </View>
          <View
            flexDirection="row"
            borderBottomColor="muted.300"
            borderBottomWidth={1}
            pb={4}>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="48px"
              h="48px"
              borderRadius="10px"
              mr={4}
              bg={mood.bgColor}>
              <FaIcon name={mood.icon} size={30} color={mood.color} />
            </Flex>
            <View flex={1}>
              <Text
                color="text.500"
                fontSize="12px"
                numberOfLines={3}
                ellipsizeMode="tail">
                {lastVibeCheck.note}
              </Text>
              <Text
                color="primary.900"
                fontSize="10px"
                flex={1}
                mt={1}
                numberOfLines={3}
                ellipsizeMode="tail">
                {format(
                  new Date(lastVibeCheck.completedAt),
                  'eeee, d MMMM yyyy'
                )}
              </Text>
            </View>
          </View>
          <WeeklyVibeCheck />
        </VStack>
      ) : (
        <EmptyListComponent
          title="Latest Vibe Check"
          description="Check in with your emotions and start building your self-awareness"
          buttonText="Complete a vibe check"
          buttonAction={() => handlePress()}
          imageSource={EmptyImage}
        />
      )}
      <Modal isVisible={isModalVisible}>
        <VibeCheckScreen closeModal={() => toggleModal()} isProfileScreen />
      </Modal>
    </HStack>
  )
}

export default LatestVibeCheck
