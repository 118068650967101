import { HStack, Select, Spinner, Text } from 'native-base'
import { useEffect, useState } from 'react'

import { useCamerasContext } from '../../providers/CameraProvider'
import FaIcon from '../_shared/FaIcon'

const CameraPicker = () => {
  const { loading, error, cameras, refresh, selectCamera, selectedCamera } =
    useCamerasContext()

  const [selectedValue, setSelectedValue] = useState(selectedCamera?.name || '')

  useEffect(() => {
    setSelectedValue(selectedCamera?.name || '')
  }, [selectedCamera])

  useEffect(() => {
    if (cameras.length === 0) refresh()
  }, [])

  const handleSelect = (selectedName: string) => {
    const selectedCamera = cameras.find(
      (camera) => camera.name === selectedName
    )
    if (selectedCamera) selectCamera(selectedCamera)
  }

  const picker = (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      space={4}
      height={10}>
      {error && <FaIcon name="exclamation-triangle" color="error.500" />}
      <Select
        flex={1}
        selectedValue={selectedValue}
        accessibilityLabel="Select Camera"
        placeholder="Select Camera"
        size="lg"
        onValueChange={handleSelect}>
        {cameras.map((cam) => (
          <Select.Item key={cam.name} label={cam.name} value={cam.name} />
        ))}
      </Select>
      <FaIcon name="rotate" onPress={() => refresh()} />
    </HStack>
  )

  const spinner = (
    <HStack
      alignItems="center"
      justifyContent="flex-start"
      space={4}
      height={10}>
      <Spinner />
      <Text>Loading cameras...</Text>
    </HStack>
  )

  return loading ? spinner : picker
}

export default CameraPicker
