import { useQuery } from '@tanstack/react-query'
import { useLegacyApi } from '../../providers/LegacyApiProvider'

export const useRewards = () => {
  const { ReferralApi } = useLegacyApi()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['Rewards'],
    queryFn: () => ReferralApi.rewards(),
  })

  return {
    data,
    isLoading,
    refetch,
  }
}
