import { Alert, Flex, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { useCamerasContext } from '../../../providers/CameraProvider'
import { useMicrophonesContext } from '../../../providers/MicrophoneProvider'
import TextLink from '../../_shared/text/TextLink'

const PermissionCheck = ({ isModerator = false }) => {
  const permissionText = isModerator ? 'Permissions' : 'Mic Permission'
  const { error: cameraError } = useCamerasContext()
  const { error: micError } = useMicrophonesContext()

  const buttonTypes = [
    {
      errorValue: 'loading',
      color: '',
      iconName: null,
      text: 'Loading...',
      isDisabled: true,
    },
    {
      errorValue: null,
      color: 'secondary',
      iconName: 'check',
      text: `${permissionText} Allowed`,
      isDisabled: false,
    },
    {
      errorValue: true,
      color: 'error',
      iconName: 'close',
      text: `${permissionText} Denied`,
      isDisabled: true,
    },
    {
      errorValue: 'request',
      color: '',
      iconName: null,
      text: `Request ${permissionText}`,
      isDisabled: false,
    },
  ]

  const [buttonType, setButtonType] = useState(buttonTypes[0])

  useEffect(() => {
    let newButtonType = buttonTypes.find((b) => b.errorValue === 'loading')

    if (isModerator) {
      if (cameraError === null && micError === null) {
        newButtonType = buttonTypes.find((b) => b.errorValue === null)
      } else if (cameraError || micError) {
        newButtonType = buttonTypes.find((b) => b.errorValue === true)
      } else {
        newButtonType = buttonTypes.find((b) => b.errorValue === 'request')
      }
    } else {
      if (micError === null) {
        newButtonType = buttonTypes.find((b) => b.errorValue === null)
      } else if (micError) {
        newButtonType = buttonTypes.find((b) => b.errorValue === true)
      } else {
        newButtonType = buttonTypes.find((b) => b.errorValue === 'request')
      }
    }

    if (newButtonType) {
      setButtonType(newButtonType)
    }
  }, [cameraError, micError, isModerator])

  const [errorDevices, setErrorDevices] = useState(null)
  useEffect(() => {
    if (cameraError && micError) {
      setErrorDevices('microphone and camera')
    } else if (cameraError) {
      setErrorDevices('camera')
    } else if (micError) {
      setErrorDevices('microphone')
    }
  }, [cameraError, micError])

  return errorDevices ? (
    <Flex mb="16px">
      <Alert colorScheme="error" variant="subtle">
        <Text>
          We're having trouble accessing your {errorDevices}.{' '}
          <TextLink href="https://www.yourcabana.com/mic-support">
            Click here
          </TextLink>{' '}
          to learn how to grant permissions on your device.
          {!isModerator &&
            errorDevices === 'microphone' &&
            'Alternatively, you can join as "Chat only" without a microphone.'}
        </Text>
      </Alert>
    </Flex>
  ) : (
    <></>
  )
}

export default PermissionCheck
