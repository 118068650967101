import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { Button, Flex, Heading, View, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import Sheet from '../_shared/card/Sheet'
import FaIcon from '../_shared/FaIcon'
import { SubscribeToCalendarModalProps } from './SubscribeToCalendarModal'

const SubscribeToCalendarModal = ({
  show,
  closeModal,
}: SubscribeToCalendarModalProps) => {
  const windowDimensions = useWindowDimensions()

  return (
    <View>
      <Modal
        useNativeDriverForBackdrop={true}
        isVisible={show}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        onBackdropPress={closeModal}>
        <Sheet
          props={{
            w: '100%',
            maxW: '320px',
            h: '420px',
            alignSelf: 'center',
            mx: '7',
          }}>
          <VStack h="100%" flex={1}>
            <Button
              variant="ghost"
              borderRadius="full"
              p="3"
              ml="auto"
              onPress={closeModal}>
              <FaIcon name="xmark" size={20} />
            </Button>
            <Flex flex="1" px="4" pb="4" alignItems="center">
              <Heading size="sm" textAlign="center" color="primary.900" mb={4}>
                Subscribe To Calendar
              </Heading>
              <AddToCalendarButton
                name="Cabana Live Groups"
                options={[
                  'Apple',
                  'Google',
                  'Microsoft365',
                  'Outlook.com',
                  'iCal',
                ]}
                buttonStyle="round"
                description="Cabana Live Groups"
                buttonsList
                subscribe
                icsFile="https://cabana-prod-service.azurewebsites.net/cabana/public/calendar/group"
                customCss="https://cabanastorage.blob.core.windows.net/public/css/atcb.css"
              />
            </Flex>
          </VStack>
        </Sheet>
      </Modal>
    </View>
  )
}

export default SubscribeToCalendarModal
