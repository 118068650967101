import {
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from 'native-base'
import useTabNavigation from '../../../hooks/useTabNavigation'
import ChecklistBullet from './ChecklistBullet'
import useCustomerIo from '../../../hooks/customerio/useCustomerIo'

const Checklist = ({
  items = [],
  itemConditions = [],
  isHorizontal = false,
}) => {
  const { navigate } = useTabNavigation()
  const isComplete = itemConditions.reduce(
    (prev, curr) => prev && curr,
    itemConditions[0]
  )
  const customerIo = useCustomerIo()


  if (isComplete) {
    customerIo.track('Daily Checklist', {
      'Is-Complete': isComplete,
      'Items-Completed': items.length,
    })
  }

  return (
    <>
      {isComplete && (
        <VStack alignItems="center" mx="24px" mb="32px">
          <Image
            source={require('../../../assets/images/ui/checked-icon.png')}
            alt="checked icon"
            width="56px"
            height="56px"
          />
          <Text
            fontWeight="700"
            fontSize="24px"
            lineHeight="32px"
            color="primary.900">
            Great work!
          </Text>
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color="primary.900"
            mx="8"
            pt="8px">
            Awesome. You completed all your daily recommendations.
          </Text>
          {!isHorizontal && (
            <Button
              mt="24px"
              w="100%"
              flex="1"
              onPress={() => navigate('Explore')}>
              Keep exploring
            </Button>
          )}
        </VStack>
      )}

      <Stack direction={isHorizontal ? 'row' : 'column'}>
        {items.map((item, index) => {
          return isHorizontal ? (
            <VStack key={index} flex="1">
              <HStack mb="3">
                <Flex flex="1" mr="5">
                  {index > 0 && (
                    <Divider bg="secondary.100" h="0.5" my="auto" />
                  )}
                </Flex>
                <ChecklistBullet isChecked={itemConditions[index]} />
                <Flex flex="1" ml="5">
                  {index < items.length - 1 && (
                    <Divider bg="secondary.100" h="0.5" my="auto" />
                  )}
                </Flex>
              </HStack>
              <Flex mx="2">{item}</Flex>
            </VStack>
          ) : (
            <HStack key={index} space="4">
              <Flex>
                <Flex flex="1" mb="2">
                  {index > 0 && (
                    <Divider
                      bg="secondary.100"
                      orientation="vertical"
                      w="0.5"
                      mx="auto"
                    />
                  )}
                </Flex>
                <ChecklistBullet isChecked={itemConditions[index]} />
                <Flex flex="1" mt="2">
                  {index < items.length - 1 && (
                    <Divider
                      bg="secondary.100"
                      orientation="vertical"
                      w="0.5"
                      mx="auto"
                    />
                  )}
                </Flex>
              </Flex>
              <Flex flex="1" my="2.5">
                {item}
              </Flex>
            </HStack>
          )
        })}
      </Stack>
    </>
  )
}

export default Checklist
