import {
  Box,
  FlatList,
  Image,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from 'native-base'
import React, { useCallback } from 'react'
import { Moderator } from '../../../../domain/models/interfaces/activityInterfaces'
import { useModerators } from '../../screens/moderators/queries'

interface ModeratorsFilterViewProps {
  appliedFilters: string[]
  setFilters: (filters: (prevFilters: string[]) => string[]) => void
  onRemoveAllFilters: () => void
}

const ModeratorsFilterView: React.FC<ModeratorsFilterViewProps> = ({
  appliedFilters = [],
  setFilters = () => {},
  onRemoveAllFilters = () => {},
}) => {
  const { data: moderators, isLoading } = useModerators('name')

  const applyFilter = useCallback(
    (filter: string) => {
      setFilters((prevFilters: string[]) => {
        return [...prevFilters, filter]
      })
    },
    [setFilters]
  )

  const removeFilter = useCallback(
    (filter: string) => {
      setFilters((prevFilters: string[]) => {
        if (prevFilters.length === 1) {
          onRemoveAllFilters()
        }
        return prevFilters.filter((prevFilter: string) => prevFilter !== filter)
      })
    },
    [setFilters, onRemoveAllFilters]
  )

  const ListSeparator = () => <Box height="12px" />

  return (
    <VStack>
      {isLoading ? (
        <FlatList
          data={Array(8).fill({})}
          numColumns={2}
          keyExtractor={(item, index) => index.toString()}
          columnWrapperStyle={{ justifyContent: 'space-between' }}
          ItemSeparatorComponent={ListSeparator}
          renderItem={() => (
            <Skeleton
              width="48%"
              height="56px"
              borderRadius="10px"
              startColor="gray.100"
              endColor="gray.200"
            />
          )}
        />
      ) : (
        <FlatList
          width="100%"
          columnWrapperStyle={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flex: 1,
          }}
          ItemSeparatorComponent={() => <Box height="12px" />}
          numColumns={2}
          data={moderators}
          keyExtractor={(item) => item.id}
          renderItem={({ item }: { item: Moderator }) => {
            const isApplied = appliedFilters.includes(item.name)
            const firstName = item.name.split(' ')[0]
            return (
              <Pressable
                width="48.5%"
                onPress={() => {
                  isApplied ? removeFilter(item.name) : applyFilter(item.name)
                }}>
                <Box
                  flex={1}
                  height="56px"
                  borderWidth="1px"
                  borderColor="primary.700"
                  backgroundColor={isApplied ? 'primary.700' : 'white'}
                  borderRadius="10px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  p={3}>
                  <Image
                    source={{ uri: item.photoUrl }}
                    alt={item.name}
                    size="40px"
                    borderRadius="full"
                    mr="10px"
                  />
                  <VStack>
                    <Text
                      fontWeight={500}
                      color={isApplied ? 'white' : 'primary.700'}>
                      {firstName}
                    </Text>
                  </VStack>
                </Box>
              </Pressable>
            )
          }}
        />
      )}
    </VStack>
  )
}

export default ModeratorsFilterView
