import { Button, Divider, HStack, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Platform } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useLegacyApi } from '../../providers/LegacyApiProvider'
import FaIcon from '../_shared/FaIcon'
import { useRewards } from './queries'
import RewardActive from './RewardActive'
import RewardCompleted from './RewardCompleted'
import RewardInactive from './RewardInactive'
const { format } = require('date-fns')

export interface Reward {
  state: 'EARNED' | 'CLAIMED' | 'DELIVERED' | null
  earnedOn: string
  rewardId: string
  referralsRequired: number
}

interface RewardItemProps {
  reward: Reward
  hasBottomBorder?: boolean
}

const RewardItem = ({ reward, hasBottomBorder }: RewardItemProps) => {
  const iPad = Platform.OS === 'ios' && Platform.isPad
  const isMobile = useMobileBreakpoint()
  const { state, referralsRequired, earnedOn, rewardId } = reward
  const { ReferralApi } = useLegacyApi()
  const { refetch } = useRewards()
  const [loading, setLoading] = useState(false)

  const reclaimReward = () => {
    setLoading(true)
    ReferralApi.reclaimReward(rewardId)
      .then(() => {
        setLoading(false)
        refetch()
      })
      .catch((e) => {
        setLoading(false)
        console.debug(e)
      })
  }

  return (
    <VStack alignItems={iPad || !isMobile ? 'center' : 'flex-start'}>
      <HStack alignItems="center">
        {(state === null && <RewardInactive />) ||
          (state === 'DELIVERED' && <RewardCompleted />) ||
          ((state === 'CLAIMED' || state === 'EARNED') && <RewardActive />)}
        <VStack ml={4}>
          <Text fontWeight={700} fontSize={16} color="primary.900">
            {`${referralsRequired} referrals`}
          </Text>
          <Text fontSize={12} color="primary.900" my={2}>
            Win a $15 gift card
          </Text>
          <HStack alignItems="center">
            <FaIcon
              size={12}
              name={state === null ? 'circle-info' : 'circle-check'}
              isSolid
              color="primary.700"
            />
            <Text fontSize={10} color="primary.900" ml={1}>
              {state === null
                ? `Complete ${referralsRequired} invites to unlock`
                : format(new Date(earnedOn), 'MMMM d, yyyy')}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      {state === 'EARNED' && (
        <Button
          isLoading={loading}
          onPress={reclaimReward}
          mt={4}
          width={232}
          alignSelf="center"
          background="secondary.500">
          <Text color="white" fontWeight={500}>
            Redeem reward
          </Text>
        </Button>
      )}
      {state === 'CLAIMED' && (
        <VStack alignSelf="center" mt={4}>
          <HStack alignItems="center">
            <FaIcon
              size={16}
              name="circle-check"
              isSolid
              color="secondary.500"
            />
            <Text fontSize={16} fontWeight={700} color="primary.900" ml={1}>
              Reward redeemed
            </Text>
          </HStack>
          <Text textAlign="center" mt={2} color="primary.900" fontSize={12}>
            Look out for an email from us{'\n'}with your $15 gift card{'\n'}
            (1-3 Business days)
          </Text>
        </VStack>
      )}
      {hasBottomBorder && <Divider color="muted.300" my={4} />}
    </VStack>
  )
}

export default RewardItem
