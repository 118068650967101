import { useQueryClient } from '@tanstack/react-query'
import { Box, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { GroupTypes } from '../../../domain/models/interfaces/activityInterfaces'
import { useLegacyApi } from '../providers/LegacyApiProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'
import DefaultModal from './modals/DefaultModal'
import MinutesProgressBarProfile from './profile/MinutesProgressBarProfile'

const DISABLED_SCREENS = ['Activity', 'Room']
const isGroup = (activity) => GroupTypes.includes(activity?.type)

const SuccessModalWrapper = ({ children, routeName, routeParams }) => {
  const { successModalShown, closeSuccessModal, previousMinutes } =
    useUserProfileContext()
  const [isShown, setIsShown] = useState(false)
  const queryClient = useQueryClient()
  const { ActivityDetailsApi } = useLegacyApi()
  const activity = queryClient.getQueryData({
    queryKey: [
      'Activity',
      'Details',
      routeParams?.activityId,
      ActivityDetailsApi,
    ],
  })

  useEffect(() => {
    if (
      (successModalShown && !DISABLED_SCREENS.includes(routeName)) ||
      (routeName === 'Activity' && isGroup(activity))
    )
      setIsShown(successModalShown)
    else setIsShown(false)
  }, [successModalShown, routeName, activity])

  return (
    <>
      <DefaultModal
        show={isShown}
        closeModal={() => closeSuccessModal()}
        action={() => {}}
        height="442px"
        body={
          <Box alignItems="center" mt="-10px">
            <Text color="secondary.600" pb="8px">
              WEEKLY PROGRESS
            </Text>
            <MinutesProgressBarProfile
              duration={2000}
              previousMinutes={previousMinutes}
              headerHeight={(200 * 3) / 2}
              colors={{
                primary: 'secondary.600',
                subtitle: 'primary.900',
                fill: '#5DBFB6',
                bg: '#E1FBF9',
              }}
              isHeader={false}
            />
          </Box>
        }
        modalInfo={{
          title: 'You’re unstoppable!',
          description: `Amazing work. You met your mental health minutes weekly goal!`,
          buttonText: 'Done',
          h: 170,
          w: 220,
        }}
      />
      {children}
    </>
  )
}

export default SuccessModalWrapper
