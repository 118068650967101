import * as Application from 'expo-application'
import { useCallback } from 'react'
import { Platform } from 'react-native'
import Configuration from '../../../domain/services/ConfigurationService'
import useAuthentication from './authentication/useAuthentication'
import useErrorToast from './useErrorToast'

export function useCabanaApi() {
  const { getAccessToken } = useAuthentication()
  const { showErrorToast } = useErrorToast()

  const apiCall = useCallback(
    async (endpoint, method = 'GET', body = null) => {
      try {
        const token = await getAccessToken()
        const response = await fetch(
          `${Configuration.CABANA_SERVICE_REST_URL}/${endpoint}`,
          {
            method,
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
              'X-App-Version':
                Platform.OS === 'web'
                  ? Configuration.WEB_VERSION
                  : Application.nativeApplicationVersion,
              'X-Platform': Platform.OS,
            },
            body: body ? JSON.stringify(body) : null,
          }
        )

        if (response.status === 204) {
          return null
        } else if (response.status === 401) {
          return null
        } else if (!response.ok) {
          throw new Error(
            `HTTP Error: ${response.status} - ${response.statusText}`
          )
        }
        return await response.json()
      } catch (error) {
        console.log('error', error)
        showErrorToast(error)
        throw error
      }
    },
    [getAccessToken]
  )

  const unregister = useCallback(
    (groupId: string, memberId: string) =>
      apiCall(`groups/${groupId}/members/${memberId}`, 'DELETE'),
    [apiCall]
  )

  const getMyGroups = useCallback(() => apiCall('groups/mine'), [apiCall])

  const activateMyAccount = useCallback(
    (referralCode: string) =>
      apiCall('members/me/activate', 'POST', { referralCode }),
    [apiCall]
  )

  return { unregister, getMyGroups, activateMyAccount }
}
