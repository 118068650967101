import { format } from 'date-fns'

export const parseSections = (groups) => {
  if (!groups) return []

  const now = new Date()
  const upcomingGroups = groups.filter((group) => group.endDate >= now)
  if (upcomingGroups.length > 0) {
    upcomingGroups[upcomingGroups.length - 1].hasBorder = true
  }
  const pastGroups = groups.filter((group) => group.endDate < now)

  let result = []
  if (upcomingGroups.length > 0) {
    result.push({
      title: 'Upcoming Groups',
      data: upcomingGroups,
    })
  }

  if (pastGroups.length > 0) {
    const pastGroupsByMonth = pastGroups.reduce((acc, group) => {
      const monthYear = format(new Date(group.endDate), 'MMMM yyyy')

      if (!acc[monthYear]) {
        acc[monthYear] = []
      }

      acc[monthYear].push(group)
      return acc
    }, {})

    const pastGroupsData = Object.keys(pastGroupsByMonth).map((monthYear) => ({
      month: monthYear,
      data: pastGroupsByMonth[monthYear],
    }))

    result.push({
      title: 'groups',
      data: pastGroupsData.reverse(),
    })
  }

  return result
}
