import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Microphone } from '../../../domain/models/interfaces/microphone'
import useStorage from '../../../domain/services/useStorage'
import useMicrophones, { UseMicrophonesResult } from '../hooks/useMicrophones'

export interface UseMicrophonesContext extends UseMicrophonesResult {
  selectMicrophone: (Microphone) => void
  selectedMicrophone: Microphone | null
  reset: () => void
}

const MicrophonesContext = createContext<UseMicrophonesContext | undefined>(
  undefined
)

export const useMicrophonesContext = (): UseMicrophonesContext =>
  useContext(MicrophonesContext)

interface MicrophonesProviderProps {
  children: ReactNode
}

export const MicrophonesProvider: React.FC<MicrophonesProviderProps> = ({
  children,
}) => {
  const { loading, microphones, error, refresh } = useMicrophones()
  const [savedMicrophone, setSavedMicrophone] = useStorage('@microphone', null)
  const [selectedMicrophone, setSelectedMicrophone] = useState<Microphone>(null)

  const selectMicrophone = (microphone: Microphone) => {
    setSelectedMicrophone(microphone)
    setSavedMicrophone(microphone)
  }

  const reset = () => {
    setSavedMicrophone(null)
  }

  useEffect(() => {
    if (microphones.length === 0) return
    if (selectedMicrophone && selectedMicrophone === savedMicrophone) return

    const matchingMicrophone = microphones.find(
      (microphone) =>
        microphone.object.deviceId === savedMicrophone?.object?.deviceId
    )

    selectMicrophone(matchingMicrophone ?? microphones[0])
  }, [microphones, savedMicrophone])

  const context: UseMicrophonesContext = {
    loading,
    microphones,
    error,
    refresh,
    selectMicrophone,
    selectedMicrophone,
    reset,
  }

  return (
    <MicrophonesContext.Provider value={context}>
      {children}
    </MicrophonesContext.Provider>
  )
}
