import { Box, HStack, Pressable, Text, VStack } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import CategoryFilter from './CategoryFilter'
import ModeratorsFilter from './ModeratorsFilter'
import TimeFilter from './TimeFilter'

const FilterSection = ({
  timeFilters,
  setTimeFilters,
  categoryFilters,
  setCategoryFilters,
  moderatorFilters,
  setModeratorFilters,
  onRemoveAllFilters,
  setIsFilterModalOpen,
  clearFilters,
}) => {
  const isDesktop = useDesktopBreakpoint()
  return (
    <VStack
      flexDir={isDesktop ? 'row' : 'column'}
      justifyContent="space-between"
      mb="24px"
      mx={5}
      alignItems={isDesktop ? 'center' : 'flex-start'}>
      <HStack justifyContent={isDesktop ? 'flex-start' : 'space-between'}>
        <TimeFilter
          setTimeFilters={setTimeFilters}
          timeFilters={timeFilters}
          onRemoveAllFilters={onRemoveAllFilters}
          setIsFilterModalOpen={setIsFilterModalOpen}
          key="timeFilter"
        />
        <CategoryFilter
          onRemoveAllFilters={onRemoveAllFilters}
          setCategoryFilters={setCategoryFilters}
          categoryFilters={categoryFilters}
          setIsFilterModalOpen={setIsFilterModalOpen}
          key="categoryFilter"
        />
        <ModeratorsFilter
          onRemoveAllFilters={onRemoveAllFilters}
          setIsFilterModalOpen={setIsFilterModalOpen}
          setModeratorFilters={setModeratorFilters}
          moderatorFilters={moderatorFilters}
          key="moderatorsFilter"
        />
      </HStack>
      {(timeFilters.length > 0 ||
        categoryFilters.length > 0 ||
        moderatorFilters.length > 0) && (
        <Box mt={isDesktop ? 0 : 4}>
          <Pressable onPress={clearFilters}>
            <Text color="white" fontSize={12}>
              Clear all
            </Text>
          </Pressable>
        </Box>
      )}
    </VStack>
  )
}

export default FilterSection
