import { useNavigationContainerRef } from '@react-navigation/native'
import { useEffect } from 'react'
import useCustomerIo from './useCustomerIo'

const usePageTracking = () => {
  const navigationRef = useNavigationContainerRef()
  const customerIo = useCustomerIo()

  useEffect(() => {
    const onRouteChange = () => {
      const route = navigationRef.getCurrentRoute()
      if (route) {
        const properties = { path: route.path, ...route.params }
        customerIo.page(route.name, properties)
      }
    }

    const unsubscribe = navigationRef.addListener('state', onRouteChange)
    return () => unsubscribe()
  }, [navigationRef])

  return navigationRef
}

export default usePageTracking
