import { Flex, Image, Text } from 'native-base'
import {
  Content,
  Group,
  groupDateFormat,
  GroupTypes,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import { capitalize } from '../../../../helpers/capitalizeString'
import useCardData from '../../../hooks/useCardData'
import FaIcon from '../FaIcon'
import AbstractCard from '../layout/AbstractCard'

interface ActivityCardProps {
  activity: Group | Content
  onPress?: () => {}
  reduceOpacityOnComplete?: boolean
  borderStyle?: {}
  hasShadow?: boolean
  deleteOnPress?: () => {}
  hasTrashIcon?: boolean
  width?: string
  isModeratorCard?: boolean
}

const ActivityCard = ({
  activity,
  onPress,
  reduceOpacityOnComplete,
  borderStyle = {
    borderColor: 'grey.200',
    borderWidth: '1',
    borderRadius: '10px',
  },
  hasTrashIcon,
  deleteOnPress,
  width,
  isModeratorCard = false,
}: ActivityCardProps) => {
  const { typeData } = useCardData(
    capitalize(activity.type),
    activity.cardBorderState ?? activity.state
  )
  const isGroup = GroupTypes.includes(capitalize(activity.type))

  return (
    <AbstractCard
      width={width}
      hasShadow={false}
      borderStyle={borderStyle}
      reduceOpacityOnComplete={reduceOpacityOnComplete}
      onPress={onPress}
      activity={activity}
      hasTrashIcon={hasTrashIcon}
      deleteOnPress={deleteOnPress}
      description={
        isGroup
          ? groupDateFormat((activity as Group).startDate)
          : (activity as Content).subtitle
      }
      leftComponent={
        isModeratorCard ? (
          <Image
            source={{ uri: activity.moderator.photoUrl }}
            alt={activity.title}
            size="56px"
            borderRadius="full"
          />
        ) : (
          <Flex
            w="56px"
            h="56px"
            borderRadius="lg"
            bg={`${typeData.color}.100`}
            px="1"
            pb="1">
            <FaIcon
              name={typeData.iconName}
              size={activity.duration ? 24 : 30}
              color={`${typeData.color}.500`}
              props={{
                m: 'auto',
              }}
            />
            {!!activity.duration && (
              <Flex bg="white" borderRadius="full">
                <Text
                  fontSize="xs"
                  textAlign="center"
                  color={`${typeData.color}.700`}>
                  {activity.duration} min
                </Text>
              </Flex>
            )}
          </Flex>
        )
      }
      title={activity.title}
    />
  )
}

export default ActivityCard
