import { useNavigation } from '@react-navigation/native'
import { Box, Button, FlatList, Text } from 'native-base'
import { useCallback, useMemo, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { WebView } from 'react-native-webview'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import ProfileItem from '../../components/profile/ProfileItem'
import ProfileContainer from '../../components/_shared/layout/ProfileContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import useLinkHandler from '../../hooks/useLinkHandler'
import { useLegacyApi } from '../../providers/LegacyApiProvider'
import { useUserProfileContext } from '../../providers/UserProfileProvider'

const AccountScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const navigation = useNavigation()
  const { userProfile } = useUserProfileContext()
  const { MemberProfileApi } = useLegacyApi()
  const [showWebView, setShowWebView] = useState(false)

  const data = useMemo(
    () =>
      userProfile
        ? [
            {
              id: 'EMAIL',
              name: userProfile.email,
              description: 'Email',
              hasRightIcon: false,
            },
            {
              id: 'PROFESSIONAL_ROLE',
              name: userProfile.professionalRoles.join(', '),
              description: 'Professional Role',
              hasRightIcon: false,
              mutedRightIcon: 'pencil',
              action: () => navigation.navigate('RoleReview'),
            },
            {
              id: 'AFFILIATION',
              name: userProfile.organization,
              description: 'Affiliation',
              hasRightIcon: false,
            },
            {
              id: 'PASSWORD',
              name: '',
              description: userProfile.hasPassword
                ? 'Password'
                : 'Add a password',
              hasRightIcon: false,
              mutedRightIcon: userProfile.hasPassword ? 'pencil' : 'plus',
              action: () => doChangePassword(),
              type: userProfile.hasPassword ? 'password' : '',
            },
          ]
        : [],
    [userProfile?.professionalRoles]
  )

  const doChangePassword = () => {
    MemberProfileApi.changePassword().then(useLinkHandler)
  }

  const handleDeleteAccount = useCallback(() => {
    const url =
      'https://forms.office.com/Pages/ResponsePage.aspx?id=uL5esOfOhkKbX9I9lbzCCXHWreRT9Q1AtAui4od-vtlUQVJJRUE3UFhDUUNFSENaQUs5ODlZUjU0TyQlQCN0PWcu'
    if (isDesktop || Platform.OS !== 'ios') {
      Linking.openURL(url).catch((error) => {
        console.debug('Cannot open URL', error)
      })
    } else {
      setShowWebView(true)
    }
  }, [isDesktop])

  return (
    <>
      <ProfileContainer headerText="Account">
        <FlatList
          showsVerticalScrollIndicator={false}
          flexGrow={isDesktop ? 0 : 1}
          borderRadius="20px"
          borderColor="grey.200"
          borderWidth={isDesktop ? 1 : 0}
          data={data}
          renderItem={({ item, index }) => {
            return (
              <ProfileItem
                item={item}
                isFirst={index === 0}
                isLast={index + 1 === data.length}
              />
            )
          }}
        />
        {!isDesktop && (
          <FooterComponent onDeleteAccount={handleDeleteAccount} />
        )}
      </ProfileContainer>
      {isDesktop && <FooterComponent onDeleteAccount={handleDeleteAccount} />}
      {Platform.OS === 'ios' && showWebView && (
        <>
          <Box
            flex={1}
            position="absolute"
            top={32}
            left={0}
            right={0}
            bottom={0}
            height="85%">
            <WebView
              source={{
                uri: 'https://forms.office.com/Pages/ResponsePage.aspx?id=uL5esOfOhkKbX9I9lbzCCXHWreRT9Q1AtAui4od-vtlUQVJJRUE3UFhDUUNFSENaQUs5ODlZUjU0TyQlQCN0PWcu',
              }}
            />
          </Box>
          <Button
            zIndex={3}
            variant="link"
            position="absolute"
            top={16}
            right={0}
            onPress={() => setShowWebView(false)}>
            Close
          </Button>
        </>
      )}
    </>
  )
}

const FooterComponent = ({ onDeleteAccount }) => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <Button
      mb={isDesktop ? 0 : 8}
      marginX="24px"
      mt={5}
      variant="empty"
      onPress={onDeleteAccount}>
      <Text color="text.500" fontSize="14px" fontWeight="500">
        Delete account
      </Text>
    </Button>
  )
}

export default secureScreen(AccountScreen)
