import { Box, FlatList } from 'native-base'
import {
  Content,
  Group,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import ActivityCard from './ActivityCard'

const borderStyle = {
  borderColor: 'muted.300',
  borderWidth: '1',
  borderRadius: '10px',
}

interface ModeratorActivityCardRowProps {
  data: (Group | Content)[]
  width?: number
  onPressLiveGroup: (item) => void
}

const ModeratorActivityCardRow = ({
  data,
  width,
  onPressLiveGroup,
}: ModeratorActivityCardRowProps) => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <FlatList
      pb={isDesktop ? 2 : 0}
      horizontal
      data={data}
      showsHorizontalScrollIndicator={isDesktop && data.length > 3}
      renderItem={({ item, index }) => {
        const cardWidthMobile = index === 0 ? width : width - 16
        return (
          <Box
            width={isDesktop ? '100%' : cardWidthMobile}
            pl={index === 0 ? '16px' : undefined}
            pr={isDesktop ? 4 : 2}>
            <ActivityCard
              isModeratorCard
              width={isDesktop ? '304px' : '100%'}
              hasShadow={false}
              borderStyle={borderStyle}
              activity={item}
              onPress={async () => onPressLiveGroup(item)}
            />
          </Box>
        )
      }}
      pt="4"
      pr="4"
      mx="-4"
    />
  )
}

export default ModeratorActivityCardRow
