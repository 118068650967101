import { Badge, Box, Divider, HStack, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import useSound from 'use-sound'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { AttendeeState30 } from '../../../../../domain/enums/AttendeeState'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useRoomContext } from '../../../providers/Room30Provider'
import Card from '../../_shared/card/Card'
import RosterItem from './RosterItem'
const chimeSound = require('../../../assets/audio/chime-sound.mp3')

const Roster = () => {
  const {
    info,
    me,
    participants: attendees,
    setShowParticipants,
  } = useRoomContext()
  const isDesktop = useDesktopBreakpoint()
  const [play] = useSound(chimeSound)

  const [moderators, setModerators] = useState([])
  const [participants, setParticipants] = useState([])
  const [registrationCount, setRegistrationCount] = useState(0)
  const [prevParticipantsCount, setPrevParticipantsCount] = useState(0)

  const sortAttendee = (a, b) => {
    // Prioritize self first
    if (b.id === me?.id) return 1
    else if (a.id === me?.id) return -1
    else return b.microphone ? 1 : -1
  }

  useEffect(() => {
    if (
      me?.role === AttendeeRole.Moderator &&
      participants.length > prevParticipantsCount
    ) {
      play()
    }
  }, [participants])

  useEffect(() => {
    if (attendees) {
      setModerators(
        attendees
          .filter((a) => a.role === AttendeeRole.Moderator)
          .sort(sortAttendee)
      )

      setParticipants(
        attendees
          .filter(
            (a) =>
              a.role !== AttendeeRole.Moderator &&
              a.connected &&
              (a.screen === AttendeeState30.Room ||
                a.screen === AttendeeState30.Lobby)
          )
          .sort(sortAttendee)
      )

      setRegistrationCount(
        attendees.filter(
          (a) =>
            a.role === AttendeeRole.Participant ||
            a.role === AttendeeRole.Spectator
        ).length
      )
    }
    setPrevParticipantsCount(participants.length)
  }, [attendees])

  const BottomComponent = () => (
    <HStack alignItems="center" w="100%">
      <Badge bg="muted.500" borderRadius="full" minW="24px">
        <Text fontSize="xs" color="text.50" bold>
          {registrationCount}
        </Text>
      </Badge>
      <Text ml="2">registered</Text>
    </HStack>
  )

  return (
    <Card
      boxProps={{
        w: !isDesktop ? '100%' : '322px',
        h: '100%',
        borderWidth: !isDesktop ? '0' : '1px',
      }}
      title={isDesktop && info.title}
      handleClose={() => setShowParticipants(false)}
      body={
        <VStack maxH={'100%'} overflowY={'auto'} overflowX={'clip'}>
          {moderators.map((moderator, index) => (
            <Box key={index}>
              <RosterItem attendee={moderator} />
            </Box>
          ))}
          {participants.length > 0 && <Divider my="2" />}
          {participants.map((participant, index) => (
            <Box key={index} my="2">
              <RosterItem attendee={participant} />
            </Box>
          ))}
        </VStack>
      }
      actions={
        !isDesktop || me?.role !== AttendeeRole.Moderator ? null : (
          <BottomComponent />
        )
      }
    />
  )
}

export default Roster
