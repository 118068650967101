import { HStack, Pressable, Text } from 'native-base'
import useTabNavigation from '../../hooks/useTabNavigation'
import FaIcon from '../_shared/FaIcon'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'

const NavHeaderLink = ({
  label,
  iconName,
  href,
  isActive = false,
  subscreen = null,
}) => {
  const { navigate } = useTabNavigation()
  const customerIo = useCustomerIo()

  const trackNavigation = () => {
    customerIo.track('Navigation Clicked', { href, label })
  }

  return (
    <Pressable
      h="100%"
      onPress={() => {
        if (subscreen) {
          navigate(href, { subscreen })
        } else {
          trackNavigation()
          navigate(href)
        }
      }}>
      <HStack
        h="100%"
        alignItems="center"
        px="3"
        mr="5"
        pt={isActive ? '4px' : '0'}
        borderBottomWidth={isActive ? '4px' : '0'}
        borderBottomColor="white">
        <FaIcon
          name={iconName}
          size={24}
          color={isActive ? 'white' : 'muted.200'}
          props={{ mr: '3' }}
        />
        <Text fontSize="xs" color={isActive ? 'white' : 'muted.200'}>
          {label}
        </Text>
      </HStack>
    </Pressable>
  )
}

export default NavHeaderLink
