import { Box, HStack, Image, Spacer, Text, View, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import GroupCardType from '../../../../../domain/enums/GroupCardType'
import {
  groupDateFormat,
  GroupTypes,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import { GroupCardProps } from '../../../../../domain/models/interfaces/cardInterfaces'
import {
  useMobileBreakpoint,
  useTabletBreakpoint,
} from '../../../../../domain/services/useBreakpoint'
import useCardData from '../../../hooks/useCardData'
import doParseActivity from '../../../hooks/useParseActivity'
import FaIcon from '../FaIcon'
import CabanaCardBorder from './CabanaCardBorder'
import TodayBadge from './TodayBadge'

const BoxProps = {
  [GroupCardType.Default]: {
    pt: '18px',
    px: '13px',
    pb: '8px',
  },
}

const TitleProps = {
  [GroupCardType.Default]: {
    fontSize: 'sm',
    fontWeight: '500',
  },
  [GroupCardType.Preview]: {
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  [GroupCardType.Full]: {
    fontSize: '2xl',
    color: 'primary.900',
    fontWeight: 'bold',
  },
}

const GroupCard = ({
  activity: rawActivity,
  onPress,
  type = GroupCardType.Default,
  width = undefined,
  testID = null,
}: GroupCardProps) => {
  const activity = doParseActivity(rawActivity)
  const { typeData, cardBorderStateData } = useCardData(
    activity.type,
    activity.cardBorderState
  )
  const isMobile = useMobileBreakpoint()
  const isTablet = useTabletBreakpoint()
  const isGroup = GroupTypes.includes(activity.type)
  const { width: screenWidth } = useWindowDimensions()
  const margin = isTablet ? 40 : 24
  const numberOfVisibleCards = isTablet ? 2 : 1
  const cardWidth =
    (screenWidth - margin * 2 - (isTablet && 8)) / numberOfVisibleCards

  return (
    <CabanaCardBorder
      testID={testID}
      width={width ?? '100%'}
      height={type === GroupCardType.Default ? '128px' : undefined}
      cardBorderState={activity.cardBorderState}
      type={type}
      cardBorderStateData={
        type === GroupCardType.Default && cardBorderStateData
      }
      onPress={type === GroupCardType.Default ? onPress : undefined}
      hasShadow={false}
      reduceOpacityOnComplete={type === GroupCardType.Default}>
      <VStack
        w="100%"
        height={type === GroupCardType.Default ? '100%' : undefined}
        bg={
          type === GroupCardType.Default && {
            linearGradient: {
              colors: [`${typeData.gradient1}`, `${typeData.gradient2}`],
              start: [0, 0],
              end: [0, 1],
            },
          }
        }
        borderRadius="lg"
        overflow="hidden"
        {...BoxProps[type]}>
        {type === GroupCardType.Default && (
          <Image
            source={typeData.waves}
            alt="thin wavy lines"
            position="absolute"
            left="0"
            top="0"
            height={cardBorderStateData.color ? '134px' : '126px'}
            width={
              cardBorderStateData.color
                ? cardWidth - 2 + 'px'
                : cardWidth + 'px'
            }
          />
        )}
        <VStack
          justifyContent={'space-between'}
          height={type === GroupCardType.Default ? '100%' : undefined}>
          <View>
            <HStack alignItems="center" mb="1">
              <FaIcon
                name={typeData.iconName}
                color={`${typeData.color}.700`}
                size={16}
                isSolid={typeData.isIconSolid}
              />
              <Text
                color={`${typeData.color}.700`}
                fontSize={type === GroupCardType.Default ? '10' : '15'}
                fontWeight="medium"
                textTransform="uppercase"
                ml="2">
                {typeData.label}
              </Text>
            </HStack>
            <Box>
              <Text
                textTransform="capitalize"
                {...TitleProps[type]}
                lineHeight="1.3em"
                numberOfLines={2}
                color={
                  type === GroupCardType.Preview ? 'primary.900' : undefined
                }>
                {activity.title}
              </Text>
            </Box>
            {isGroup && (
              <HStack alignItems="center" mt="2px">
                <TodayBadge date={activity.startDate} typeData={typeData} />
                <Text
                  color={`${typeData.color}.700`}
                  fontSize={type === GroupCardType.Full ? '14px' : '12px'}>
                  {groupDateFormat(activity.startDate)}
                </Text>
              </HStack>
            )}
          </View>
          <HStack
            alignItems="center"
            space="2"
            mt={type !== GroupCardType.Default ? '16px' : undefined}>
            <Image
              source={{ uri: activity?.moderator?.photoUrl }}
              alt="Moderator photo"
              size={
                type === GroupCardType.Full
                  ? isMobile
                    ? '24px'
                    : '32px'
                  : type === GroupCardType.Preview
                  ? '24px'
                  : '16px'
              }
              borderRadius="full"
            />
            <Text
              fontSize={
                type === GroupCardType.Full && !isMobile ? '16px' : '12px'
              }
              color={
                type !== GroupCardType.Default ? 'primary.900' : 'primary.700'
              }
              ellipsizeMode="tail"
              numberOfLines={1}>
              {activity?.moderator?.name}
            </Text>
            <Spacer />
            {!!activity.duration && (
              <Box
                backgroundColor={
                  type === GroupCardType.Default ? 'white' : 'muted.100'
                }
                borderRadius="20px">
                <Text
                  fontSize={
                    type === GroupCardType.Full && !isMobile ? '14px' : '12px'
                  }
                  color={
                    type === GroupCardType.Default
                      ? `${typeData.color}.700`
                      : 'primary.900'
                  }
                  px={type === GroupCardType.Full && !isMobile ? '8px' : '4px'}
                  py="2px">
                  {activity.duration} min
                </Text>
              </Box>
            )}
          </HStack>
        </VStack>
      </VStack>
    </CabanaCardBorder>
  )
}

export default GroupCard
