import { Box, Divider, Text } from 'native-base'
import { useEffect } from 'react'
import SEARCH_STATUS from '../../../../domain/enums/ExploreSearch'
import SearchResults from './SearchResults'
import SuggestedAndPopular from './SuggestedAndPopular'

const SearchComponent = ({
  searchValue,
  timeFilters = [],
  categoryFilters = [],
  moderatorsFilters = [],
  searchStatus,
  setSearchStatus,
  handleSubmit,
}) => {
  useEffect(() => {
    if (searchStatus != SEARCH_STATUS.finished) {
      if (searchValue === '') {
        if (timeFilters.length > 0 || categoryFilters.length > 0) {
          setSearchStatus(SEARCH_STATUS.finished)
        } else {
          setSearchStatus(SEARCH_STATUS.started)
        }
      } else setSearchStatus(SEARCH_STATUS.typing)
    }
  }, [searchValue])

  return (
    <>
      <Box px="24px">
        <Text fontWeight="bold" pt="24px" pb="6px">
          {searchStatus}
        </Text>
        <Divider />
      </Box>
      {searchStatus == SEARCH_STATUS.finished ? (
        <SearchResults
          searchValue={searchValue}
          timeFilters={timeFilters}
          categoryFilters={categoryFilters}
          moderatorsFilters={moderatorsFilters}
        />
      ) : (
        <Box px="24px">
          <SuggestedAndPopular
            searchValue={searchValue}
            handleSubmit={handleSubmit}
          />
        </Box>
      )}
    </>
  )
}

export default SearchComponent
