import { useEffect } from 'react'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import ProfileViewDesktop from '../components/profile/ProfileViewDesktop'
import ProfileViewMobile from '../components/profile/ProfileViewMobile'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import secureScreen from '../hooks/authentication/secureScreen'
import DefaultLayout from '../layouts/DefaultLayout'
import useCustomerIo from '../hooks/customerio/useCustomerIo'

const ProfileScreen = ({ route }) => {
  const isDesktop = useDesktopBreakpoint()
  const selectedScreen = route.params?.subscreen
  const customerIo = useCustomerIo()

  useEffect(() => {
    customerIo.track('Profile Reached')
  }, [])

  return (
    <DefaultLayout
      scrollView={isDesktop ? true : false}
      activeLabel="Profile"
      bg={isDesktop ? 'muted.50' : 'bgDefault'}
      hasScrollBar={true}>
      {!isDesktop ? (
        <ProfileViewMobile selectedScreen={selectedScreen} />
      ) : (
        <FixedContainer size="lg">
          <ProfileViewDesktop selectedScreen={selectedScreen} />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default secureScreen(ProfileScreen)
