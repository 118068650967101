import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Configuration from '../../../domain/services/ConfigurationService'
import CodePushComponent from '../components/codepush/CodePushComponent'
import AuthenticationProvider from './authentication/AuthenticationProvider'
import { FeatureProvider } from './FeatureProvider'
import IntercomProviderAdapter from './IntercomProviderAdapter'
import { LegacyApiProvider } from './LegacyApiProvider'
import { ThemeProvider } from './ThemeProvider'

const INTERCOM_APP_ID = Configuration.INTERCOM_APP_ID

const AppProvider = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  //   useEffect(() => {
  //     LogBox.ignoreLogs(['VirtualizedLists should never be nested inside'])
  //   }, [])

  return (
    <ThemeProvider>
      <CodePushComponent>
        <IntercomProviderAdapter appId={INTERCOM_APP_ID}>
          <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
              <FeatureProvider>
                <LegacyApiProvider>{children}</LegacyApiProvider>
              </FeatureProvider>
            </AuthenticationProvider>
          </QueryClientProvider>
        </IntercomProviderAdapter>
      </CodePushComponent>
    </ThemeProvider>
  )
}

export default AppProvider
