import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, HStack, VStack } from 'native-base'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import { NavigationParams } from '../../../screens/NavigationScreen'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreaHeading from '../FocusAreaHeading'
import VoyageChip from '../VoyageChip'
import PathStep from './PathStep'
import useCustomerIo from '../../../hooks/customerio/useCustomerIo'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)

const PathDesktop = ({ data, isLoading, focusAreaName, orderedSteps }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { icon, color } = useRenderFocusAreaIcon(focusAreaName)
  const customerIo = useCustomerIo()

  const firstIncompleteStep = orderedSteps.findIndex(
    (step) => step.completed === false
  )

  return (
    <Box bg="muted.50" mb={6}>
      <HStack alignItems="center" justifyContent="space-between" my={2}>
        <BackButton />
        {!isLoading && data && (
          <VoyageChip
            bg={data.stepsCompleted > 0 ? 'success.200' : 'muted.200'}
            text={`${data.stepsCompleted}/${data.steps.length}`}
          />
        )}
      </HStack>
      <HStack>
        <VStack w={'35%'}>
          <Box
            borderRadius="20px"
            bg="white"
            p={4}
            borderColor="grey.200"
            borderWidth={1}>
            {isLoading ? (
              <LoadingView />
            ) : (
              <FocusAreaHeading
                name={focusAreaName}
                description={data.description}
                color={color}
                icon={icon}
              />
            )}
          </Box>
        </VStack>

        <VStack ml={6} w="624px">
          <Box
            borderRadius="20px"
            bg="white"
            p={4}
            borderColor="grey.200"
            borderWidth={1}>
            {isLoading ? (
              <SkeletonLoader size={{ width: '100%', height: '300px' }} />
            ) : (
              orderedSteps.map((step, index) => (
                <PathStep
                  key={index}
                  step={step}
                  index={index}
                  firstIncompleteStep={firstIncompleteStep}
                  goToActivity={() => {
                    customerIo.track('Path Started', {
                      title: data.name,
                      focusArea: focusAreaName,
                      progress: data.stepsCompleted,
                      url: window.location?.href,
                    })

                    customerIo.track('Focus Area Started', {
                      path: data.name,
                      title: focusAreaName,
                      progress: data.stepsCompleted,
                    })

                    if (step.id === 'COMPASS') {
                      navigation.navigate('Compass')
                    } else {
                      navigation.navigate('Activity', {
                        activityId: step.id,
                        stepId: step.stepId,
                      })
                    }
                  }}
                  dataLength={orderedSteps.length}
                />
              ))
            )}
          </Box>
        </VStack>
      </HStack>
    </Box>
  )
}

export default PathDesktop
