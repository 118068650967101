import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Image, Pressable, Text, VStack } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { NavigationParams } from '../../screens/NavigationScreen'

const ModeratorAvatar = ({ moderator }) => {
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const firstName = moderator.name.split(' ')[0]

  return (
    <Pressable
      onPress={() =>
        navigation.navigate('ModeratorDetail', { moderatorId: moderator.id })
      }>
      <VStack alignItems="center" mr={isDesktop ? 10 : 6}>
        <Image
          source={{ uri: moderator.photoUrl }}
          alt="Moderator photo"
          borderRadius="full"
          width="56px"
          height="56px"
        />
        <Text mt={2} color="text.900" fontWeight={500} lineHeight="18px">
          {firstName}
        </Text>
      </VStack>
    </Pressable>
  )
}

export default ModeratorAvatar
