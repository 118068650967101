import { useNavigation } from '@react-navigation/native'
import { Button, Text } from 'native-base'
import { useCallback, useState } from 'react'
import useCalendar from '../../../hooks/useCalendar'
import DefaultModal from '../../modals/DefaultModal'
import ProgressModal from '../../_shared/ProgressModal'

const AddToCalendarButtonAndModal = ({
  activity,
  handleClose: handleParentClose,
  buttonStyle = {},
  buttonHidden = false,
}) => {
  const navigation = useNavigation()
  const [actionModalShown, setActionModalShown] = useState(false)
  const [actionModalTotallyHidden, setActionModalTotallyHidden] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSuccessCallback = useCallback(() => {
    setTimeout(() => {
      setIsSubmitting(true)
    }, 400)
  }, [])
  const { addEvent } = useCalendar(activity.id, onSuccessCallback)

  const addEventCallback = useCallback(() => {
    addEvent(activity.title, activity.startDate, activity.endDate)
  }, [activity])

  const modalInfo = {
    title: 'You saved your spot!',
    description:
      'You’ll get a confirmation email and reminder before your live group.',
    buttonText: 'Add to calendar',
    image: require('../../../assets/images/live-groups/modals/attending.png'),
    action: () => {
      addEventCallback()
    },
  }

  return (
    <>
      <DefaultModal
        activity={activity}
        show={actionModalShown}
        action={() => {
          modalInfo.action()
        }}
        modalInfo={modalInfo}
        closeModal={() => {
          setActionModalShown(false)
          //Should work with onModalHide, but it doesn't, the default timeout for the modal to hide totally is 300ms
          setTimeout(async () => setActionModalTotallyHidden(true), 300)
        }}
      />
      {!buttonHidden && (
        <Button onPress={() => setActionModalShown(true)} style={buttonStyle}>
          <Text fontSize="md" fontWeight={500} color="white">
            Add to calendar
          </Text>
        </Button>
      )}
      <ProgressModal
        downloadProgress={100}
        isVisible={isSubmitting}
        successMessage={<Text>Live group added to calendar!</Text>}
        animationDuration={1000}
        position={{
          bottom: 100,
        }}
        callback={() => {
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }}
      />
    </>
  )
}

export default AddToCalendarButtonAndModal
