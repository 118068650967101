import { Button, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { useRoomContext } from '../../../providers/Room30Provider'
import FaIcon from '../../_shared/FaIcon'

const isMoreThan15MinutesBeforeNow = (date: Date) => {
  const now = new Date()
  const fifteenMinutes = 15 * 60 * 1000
  return date.getTime() - now.getTime() > fifteenMinutes
}

const useTimeCheck = (date: Date) => {
  const [isOk, setIsOk] = useState(false)
  const timeCheck = () => {
    setIsOk(!isMoreThan15MinutesBeforeNow(date))
  }

  useEffect(() => {
    timeCheck()
    const interval = setInterval(timeCheck, 1000 * 30)
    return () => clearInterval(interval)
  }, [date])

  return isOk
}

const StartRoom = () => {
  const { me, info, start, stop, setRoomStarted } = useRoomContext()
  const [isActive, setActive] = useState(false)
  const startButtonEnabled = useTimeCheck(new Date(info.startTime))

  const toggleIsActive = () => {
    if (!isActive) {
      start().then(() => {
        setRoomStarted(true)
      })
    } else {
      stop()
    }
  }

  useEffect(() => {
    setActive(me.screen === 'ROOM')
  }, [me.screen])

  return (
    <>
      <Button
        backgroundColor={
          isActive
            ? 'error.500'
            : startButtonEnabled
            ? 'secondary.500'
            : 'muted.400'
        }
        borderRadius="full"
        leftIcon={
          <FaIcon
            name={isActive ? 'arrow-right-from-bracket' : 'circle-play'}
            size={20}
            color={startButtonEnabled ? 'text.50' : 'text.700'}
          />
        }
        onPress={toggleIsActive}
        disabled={!startButtonEnabled}>
        <Text color={startButtonEnabled ? 'text.50' : 'text.700'}>
          {isActive ? 'Stop' : 'Start'} group
        </Text>
      </Button>

      {!startButtonEnabled && (
        <Text ml="10px" mt="10px" color="primary.900">
          You'll be able to start this group 15 minutes before its scheduled
          start time
        </Text>
      )}
    </>
  )
}

export default StartRoom
