import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { Divider, Text, VStack } from 'native-base'
import Configuration from '../../../../../domain/services/ConfigurationService'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'

const CalendarButtons = ({ activity }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <VStack mt={isMobile ? '16px' : '18px'}>
      <Divider />
      <Text
        alignSelf="center"
        my={isMobile ? '10px' : '16px'}
        color="primary.900"
        fontWeight="700"
        fontSize="18px">
        Add to your calendar
      </Text>
      <AddToCalendarButton
        name={activity.title}
        startDate={activity.startDate.toISOString()}
        endDate={activity.endDate.toISOString()}
        location={`${Configuration.CABANA_UI}/activity?activityId=${activity.id}`}
        description={activity.description}
        options={['Apple', 'Google', 'Microsoft365', 'Outlook.com', 'iCal']}
        buttonStyle="round"
        buttonsList
        iCalFileName={activity.title}
        customCss={`https://cabanastorage.blob.core.windows.net/public/css/atcb${
          isMobile ? '-mobile' : ''
        }.css`}></AddToCalendarButton>
    </VStack>
  )
}

export default CalendarButtons
