import { Box, Pressable, Text, useToast } from 'native-base'
import { Linking } from 'react-native'

const useErrorToast = () => {
  const errorToast = useToast()

  const toast = (
    <Pressable
      onPress={() =>
        Linking.openURL('mailto:support@mycabana.health')
          .then((data) => console.debug(data))
          .catch((data) => console.debug(data))
      }>
      <Box bg="#646464" px="2" py="1" rounded="sm" mb={5}>
        <Text color="white">
          Oops! Something went wrong. Contact support at
          support@mycabana.health.
        </Text>
      </Box>
    </Pressable>
  )

  return {
    showErrorToast: (error: Error) =>
      errorToast.show({
        render: () => toast,
        placement: 'top',
      }),
  }
}

export default useErrorToast
