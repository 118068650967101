import { Pressable, Text, VStack } from 'native-base'
import useTabNavigation from '../../hooks/useTabNavigation'
import FaIcon from '../_shared/FaIcon'
import useCustomerIo from '../../hooks/customerio/useCustomerIo'

const NavFooterLink = ({ label, iconName, href, isActive = false, testID }) => {
  const { navigate } = useTabNavigation()
  const customerIo = useCustomerIo()

  const trackNavigation = () => {
    customerIo.track('Navigation Clicked', { href, label })
  }

  return (
    <Pressable
      testID={testID}
      onPress={() => {
        trackNavigation()
        navigate(href)
      }}>
      <VStack justifyContent="center" textAlign="center">
        <FaIcon
          name={iconName}
          size={24}
          color={isActive ? 'secondary.500' : 'muted.500'}
          props={{ mx: 'auto', mb: '1' }}
        />
        <Text
          fontSize="2xs"
          color={isActive ? 'secondary.500' : 'muted.500'}
          fontWeight={isActive ? 'bold' : 'normal'}>
          {label}
        </Text>
      </VStack>
    </Pressable>
  )
}

export default NavFooterLink
