export const useIframeSource = (inputString: string, isIframe = true) => {
  if (!inputString) return null

  if (isIframe) {
    // Check if it's already a direct URL (not an iframe)
    if (!inputString.includes('<iframe')) {
      return inputString
    }

    // Handle iframe format
    const iframeRegex = /<iframe\s+src="([^#]+)#/
    const iframeMatch = inputString.match(iframeRegex)
    if (iframeMatch && iframeMatch[1]) {
      return iframeMatch[1]
    }
  } else {
    const hashIndex = inputString.indexOf('#')
    if (hashIndex !== -1) {
      return inputString.substring(0, hashIndex)
    }
    return inputString
  }
  return null
}
