import { useState } from 'react'
import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import delayPromise from '../../helpers/delayPromise'
import { UseCameras } from './useCameras'

const fetchCameras: () => Promise<Camera[]> = async () => {
  // permission check
  await navigator.mediaDevices.getUserMedia({ video: true })

  // map devices to cameras
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter((device) => device.kind === 'videoinput')
  const cameras = videoDevices.map((device) => ({
    name: device.label || 'Unnamed camera',
    object: device,
  }))
  return cameras
}

export const useCameras: UseCameras = () => {
  const [loading, setLoading] = useState(false)
  const [cameras, setCameras] = useState<Camera[]>([])
  const [error, setError] = useState<string | null>(null)

  const refresh = async () => {
    setLoading(true)
    try {
      const fetchedCameras = await delayPromise(fetchCameras(), 500)

      setCameras([...fetchedCameras, NO_CAMERA])
      setError(null)
    } catch (error) {
      await delayPromise(Promise.resolve(), 500)

      setCameras([NO_CAMERA])
      setError(error.message)
    }
    setLoading(false)
  }

  return {
    loading,
    cameras,
    error,
    refresh,
  }
}

export default useCameras
