import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Flex, Heading, HStack, Spacer, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Pressable, useWindowDimensions } from 'react-native'
import { useTabletBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import { NavigationParams } from '../../screens/NavigationScreen'
import ActivityModalPreview from '../explore/activities/ActivityModalPreview'
import CardRow from '../_shared/card/CardRow'
import ModeratorActivityCardRow from '../_shared/card/ModeratorActivityCardRow'
import Checklist from './dailyRecs/Checklist'
import InspirationalQuote from './InspirationalQuote'

const HomeMobileView = ({
  upcomingGroups,
  checklistItems,
  checklistConditions,
  myGroups,
}) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { userProfile } = useUserProfileContext()
  const { width } = useWindowDimensions()

  const [modalLiveGroupData, setModalLiveGroupData] = useState(null)
  const [modalLiveGroupShown, setModalLiveGroupShown] = useState(false)
  const handleCloseModal = () => setModalLiveGroupShown(false)

  const isTablet = useTabletBreakpoint()
  const numberOfVisibleCards = isTablet ? 2 : 1
  const margin = isTablet ? 40 : 24
  const cardWidth =
    (width - margin * 2 - (isTablet && 8)) / numberOfVisibleCards

  let goalHeading = 'Daily Boost'
  if (userProfile?.goal === 'REDUCE_STRESS') {
    goalHeading = 'Daily Stress Relief'
  } else if (userProfile?.goal === 'BUILD_SKILLS') {
    goalHeading = 'Daily Skill Builder'
  }

  const onPressLiveGroup = (activity) => {
    setModalLiveGroupData(activity)
    setModalLiveGroupShown(true)
  }

  return (
    <Flex flex="1" bg="white" borderRadius="2xl" mt="200px" px="16px">
      <VStack justifyContent="center" pt="24px">
        {!!myGroups?.length && (
          <>
            <HStack alignItems="center">
              <Heading size="md">My Groups</Heading>
              <Spacer />
              <Pressable onPress={() => navigation.navigate('MyGroups')}>
                <Text fontSize="16px">see all</Text>
              </Pressable>
            </HStack>
            <CardRow
              width={cardWidth}
              onPressLiveGroup={onPressLiveGroup}
              data={myGroups}
            />
          </>
        )}
        {!!upcomingGroups?.length && (
          <VStack mt={6}>
            <HStack alignItems="center">
              <Heading size="md">Upcoming Groups</Heading>
              <Spacer />
              <Pressable onPress={() => navigation.navigate('LiveGroups')}>
                <Text fontSize="16px">see all</Text>
              </Pressable>
            </HStack>
            <ModeratorActivityCardRow
              width={width - 24}
              onPressLiveGroup={onPressLiveGroup}
              data={upcomingGroups}
            />
          </VStack>
        )}
        <Heading
          size="md"
          mt={!!upcomingGroups?.length ? '6' : undefined}
          mb="3">
          {goalHeading}
        </Heading>
        <Checklist
          items={checklistItems}
          itemConditions={checklistConditions}
        />
        <InspirationalQuote />
      </VStack>
      <ActivityModalPreview
        modalData={modalLiveGroupData}
        modalShown={modalLiveGroupShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: null,
          hasCloseOnBottom: false,
        }}
      />
    </Flex>
  )
}

export default HomeMobileView
