import { Box, View, VStack } from 'native-base'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import ModeratorCustomHeaderMobile from '../../components/moderators/ModeratorCustomHeaderMobile'
import ModeratorDetail from '../../components/moderators/ModeratorDetail'
import ModeratorHeaderDesktop from '../../components/moderators/ModeratorHeaderDesktop'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import { SkeletonLoader } from '../../components/_shared/SkeletonLoader'
import secureScreen from '../../hooks/authentication/secureScreen'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useModeratorDetail } from './queries'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" p={6}>
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
  </VStack>
)

const ModeratorDetailScreen = ({ route }) => {
  const { moderatorId } = route.params
  const { data, isLoading } = useModeratorDetail(moderatorId)
  const isMobile = useMobileBreakpoint()

  return (
    <>
      {isMobile ? (
        <View flex={1} bg="white">
          <ModeratorCustomHeaderMobile isLoading={isLoading} moderator={data} />
          {isLoading ? <LoadingView /> : <ModeratorDetail moderator={data} />}
        </View>
      ) : (
        <DefaultLayout activeLabel="Moderator Details" hasScrollBar={true}>
          <FixedContainer width="1024px" size="lg">
            <Box
              mt={10}
              marginBottom={4}
              borderColor="grey.200"
              borderWidth={1}
              backgroundColor="white"
              borderRadius="20px">
              {isLoading ? (
                <LoadingView />
              ) : (
                <>
                  <ModeratorHeaderDesktop moderator={data} />
                  <ModeratorDetail moderator={data} />
                </>
              )}
            </Box>
          </FixedContainer>
        </DefaultLayout>
      )}
    </>
  )
}

export default secureScreen(ModeratorDetailScreen)
